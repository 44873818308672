import { useMutation, useQuery } from '@apollo/client'
import { Avatar, makeStyles } from '@material-ui/core'
import { ReactElement, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  MUTATION_EDIT_AUTHOR,
  QUERY_GET_ALL_AUTHORS,
  QUERY_GET_AUTHOR_BY_ID,
} from '@Api/author_api'
import Loading from '@Components/Loading'
// import author_api from '@Api/author_api'
import Form from '@Components/Author/Form'
import { authorType } from '@Types/author_type'
import { styles } from '@Components/Author/form.style'
import useUpload from '@Hooks/useUpload'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
// import { checkPermission } from "@Components/checkPermission"

const useStyles = makeStyles(styles)
interface ParamType {
  id: string
}
export default function EditAuthor(): ReactElement {
  const { checkPermission } = useAuth() as AuthContextType

  useEffect(() => {
    const checked = checkPermission('author_edit')
    if (!checked) {
      window.location.href = '/'
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams<ParamType>()
  const [imageProfile, setImageProfile] = useState<FileList>()
  const { onUpload } = useUpload()
  const [editAuthor] = useMutation(MUTATION_EDIT_AUTHOR)
  const { loading, data, error } = useQuery(QUERY_GET_AUTHOR_BY_ID, {
    variables: {
      _id: id,
    },
    fetchPolicy: 'network-only',
  })
  const handleSubmit = async (obj: authorType): Promise<number | null> => {
    let current_img = data.profile_image
    if (imageProfile) {
      const res_image = await onUpload(imageProfile, 'image_files', {
        ref_id: id,
        type: 'replace',
      })
      current_img = res_image.data.data?.items[0]?.url
    }

    const res = await editAuthor({
      variables: {
        ...obj,
        profile_image: current_img,
        _id: id,
      },
      refetchQueries: [
        {
          query: QUERY_GET_ALL_AUTHORS,
        },
      ],
    })
    if (res.data.EditAuthor.statusCode === 200) {
      history.push('/author')
      return null
    } else {
      return res?.data?.EditAuthor?.statusCode || 'error'
    }
  }
  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imgs = event.target.files
    if (imgs) setImageProfile(imgs)
  }

  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <>
      {!loading && (
        <Form
          defaultData={data?.Author}
          avatar={() => {
            return (
              <>
                <Avatar
                  className={classes.avatar}
                  src={
                    imageProfile
                      ? URL.createObjectURL(imageProfile[0])
                      : data?.Author?.profile_image
                  }
                />
                <input
                  type="file"
                  onChange={handleChangeFile}
                  className={classes.upload_profile}
                />
              </>
            )
          }}
          onSubmit={handleSubmit}
          title="แก้ไขข้อมูลผู้เขียน"
        />
      )}
    </>
  )
}
