import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import ForwardIcon from '@material-ui/icons/Forward'
import { useAuth } from '@Hooks/useAuth'
import { CustomTheme } from '../../theme'
import { useGlobal } from '@Hooks/useGlobal'

const useStyles = makeStyles<CustomTheme, {}, string>((theme) => ({
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '40ch',
      display: 'flex',
    },
    padding: '24px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: '#f5f5f5',
  },
  card: {
    display: 'flex',
    maxWidth: '800px',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  logo: {
    padding: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.custom.app_color,
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
}))

export default function Login() {
  const classes = useStyles()
  const { login, errors } = useAuth()
  const { wording } = useGlobal()
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      username: { value: string }
      password: { value: string }
    }
    const isSuccess = await login({
      username: target.username.value,
      password: target.password.value,
    })

    if (isSuccess) {
      window.location.reload()
    }
  }
  return (
    <>
      <div className={classes.container}>
        <Box boxShadow={2} className={classes.card}>
          <Box className={classes.logo}>
            <Typography style={{ color: '#fff' }} variant="h5">
              {wording['app_name']}
            </Typography>
          </Box>

          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              id="username"
              name="username"
              label="Username"
              variant="outlined"
              size="small"
              fullWidth
            />
            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              size="small"
              type="password"
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              endIcon={<ForwardIcon />}
              type="submit"
            >
              Login
            </Button>
            <label style={{ color: 'red' }}>{errors?.message}</label>
          </form>
        </Box>
      </div>
    </>
  )
}
