import { Fragment, useContext } from 'react'
import { Box, makeStyles, TextField } from '@material-ui/core'
import { VideoContentContext, VideoContentContextType } from '../context/VideoContext'
import { styles } from './styles'
import { useGlobal } from '@Hooks/useGlobal'

const useStyles = makeStyles(styles)

export default function Main() {
  const {
    handleChange,
    content: [content],
  } = useContext(VideoContentContext) as VideoContentContextType
  const { wording } = useGlobal()
  const classes = useStyles()
  return (
    <Fragment>
      <Box className={classes.mainContainer}>
        <div className={classes.mainItem}>
          <input
            id="outlined-textarea"
            placeholder="หัวข้อ"
            value={content?.title || ''}
            style={{
              fontSize: '30px',
              border: '0',
              outline: 'none',
              width: '100%',
            }}
            onChange={(ev) => {
              handleChange('title', ev.target.value)
            }}
          />
        </div>
       
        <div className={classes.mainItem}>
          <TextField
            id="outlined-textarea"
            multiline
            rowsMax={5}
            placeholder={wording.content_page.summary}
            value={content?.summary || ''}
            fullWidth
            onChange={(ev) => {
              handleChange('summary', ev.target.value)
            }}
          />
        </div>
      </Box>
    </Fragment>
  )
}
