import { useMutation, useQuery } from '@apollo/client'
import { Avatar, makeStyles } from '@material-ui/core'
import { ReactElement, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  MUTATION_EDIT_FOLLOW,
  QUERY_GET_ALL_FOLLOWS,
  QUERY_GET_FOLLOW_BY_ID,
} from '@Api/follow_api'
import Loading from '@Components/Loading'
import Form from '@Components/Follow/Form'
import { styles } from '@Components/Follow/form.style'
import useUpload from '@Hooks/useUpload'
import { followType } from '@Types/follow_type'
import { AuthContextType, useAuth } from '@Hooks/useAuth'

const useStyles = makeStyles(styles)
interface ParamType {
  id: string
}
export default function EditFollow(): ReactElement {
  const { checkPermission } = useAuth() as AuthContextType

  useEffect(() => {
    const checked = checkPermission('author_edit')
    if (!checked) {
      window.location.href = '/'
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams<ParamType>()
  const [imageProfile, setImageProfile] = useState<FileList>()
  const { onUpload } = useUpload()
  const [editFollow] = useMutation(MUTATION_EDIT_FOLLOW)
  const { loading, data, error } = useQuery(QUERY_GET_FOLLOW_BY_ID, {
    variables: {
      _id: id,
    },
    fetchPolicy: 'network-only',
  })

  const handleSubmit = async (obj: followType) => {
    let current_img = data.cover
    
    if (imageProfile) {
      const res_image = await onUpload(imageProfile, 'image_files', {
        ref_id: id,
        type: 'replace',
      })
      current_img = res_image.data.data?.items[0]?.url
    }

    const res = await editFollow({
      variables: {
        ...obj,
        cover: current_img,
        _id: id,
      },
      refetchQueries: [
        {
          query: QUERY_GET_ALL_FOLLOWS,
        },
      ],
    })

    if (res.data.EditFollow.statusCode === 200) {
      history.push('/follow')
    }
  }

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imgs = event.target.files
    if (imgs) setImageProfile(imgs)
  }

  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <>
      {!loading && (
        <Form
          defaultData={data?.Follow}
          avatar={() => {
            return (
              <>
                <Avatar
                  className={classes.avatar}
                  src={
                    imageProfile
                      ? URL.createObjectURL(imageProfile[0])
                      : data?.Follow?.cover
                  }
                />
                <input
                  type="file"
                  onChange={handleChangeFile}
                  className={classes.upload_profile}
                />
                <small className={classes.recommended}>ขนาดของรูปที่เหมาะสมเป็นอัตราส่วน 1:1 [450 x 450]</small>
              </>
            )
          }}
          onSubmit={handleSubmit}
          title="แก้ไขข้อมูล follow"
        />
      )}
    </>
  )
}
