import React, { Fragment, useState } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
import Detail from './detail'
interface StyledTabProps {
  label?: string
  disabled?: boolean
  chlidren?: React.ReactNode
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  padding: {
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  header_container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  status_wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginInline: theme.spacing(1),
  },
  saveBtn: {
    background: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      background: '#439c46',
    },
    margin: theme.spacing(1),
  },
}))
const PrPick = () => {
  const classes = useStyles()
  const [tabNow, setTabNow] = useState(0)
  const [data] = useState({
    Topics: [
      {
        id: 'home',
        value: 'Home',
      },
    ],
  })
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNow(newValue)
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.demo1}>
          <AntTabs onChange={handleChange} value={tabNow}>
            {data?.Topics &&
              data.Topics.map((topic) => (
                <AntTab key={topic.id} label={topic.value} />
              ))}
          </AntTabs>
          <div className={classes.padding}>
            <Detail topic={data?.Topics[tabNow]?.id} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    position: 'relative',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      marginRight: theme.spacing(3),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => (
  <Tab disableRipple {...props}>
    {props.chlidren}
  </Tab>
))

export default PrPick
