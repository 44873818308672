import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Grid,
  Link,
  Button
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { QUERY_GET_ALL_CONTENTS } from '@Api/content_api'
import Loading from '@Components/Loading'
import { IContent } from '@Types/content_type'
import ButtonLink from '@Components/ButtonLink'
import SearchForm, { FormSearchType } from './Widget/searchForm'
import { Pagination } from '@material-ui/lab'
import { Add } from '@material-ui/icons'
import { CONTENT } from '@Libs/const'
import { useAuth } from '@Hooks/useAuth'
import { useGlobal } from '@Hooks/useGlobal'
import { MenuBook } from '@material-ui/icons'
// import { checkPermission } from "@Components/checkPermission"

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    width: '100%',
    // minHeight: 650,
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minHeight: 650,
  },
  pagination: {
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(1),
  },
  box_none: {
    boxShadow: 'none',
  },
  button_add: {
    marginInline: theme.spacing(1.5),
  },
}))
const ListContent = () => {
  const { checkPermission } = useAuth()
  const [addContentBtn, setAddContentBtn] = useState<boolean>(false)
  const [isStorytelling, setIsStoryTelling] = useState<boolean>(false)
  const { wording } = useGlobal()
  useEffect(() => {
    const checked = checkPermission('content_view')
    const editCheck = checkPermission('content_edit')
    if (!checked) {
      window.location.href = '/'
    }

    if (editCheck) {
      setAddContentBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const { onInitContent } = useContext(ContentContext) as ContentContextType
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    limit: 10,
  })
  const history = useHistory()
  const classes = useStyles()
  const [parameter, setParameter] = useState<FormSearchType>()
  const { loading, error, data, refetch } = useQuery(QUERY_GET_ALL_CONTENTS, {
    variables: {
      contentParams: {
        ...parameter,
        order_by: [
          {
            field: 'updated_time',
            sort: 'desc',
          },
        ],
        // has_video: false // for test has_video
      },
      ...pageInfo,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })
  console.log(data, "content===list", refetch)
  const handleSubmit = (form: FormSearchType) => {
    setPageInfo({ ...pageInfo, page: 1 })
    const condition = { ...form }
    if (isStorytelling) condition.storytelling = { $exists: true, $ne: null }
    setParameter(condition)
    refetch({
      contentParams: {
        ...condition,
        order_by: [
          {
            field: 'updated_time',
            sort: 'desc',
          },
        ],
        // has_video: false // for test has_video
      },
      limit: pageInfo.limit,
      page: 1,
    })
  }
  const handleStorytelling = (active: boolean = true) => {
    setPageInfo({ ...pageInfo, page: 1 })
    const condition: {
      storytelling?: object
    } = {}
    if (active) condition.storytelling = { $exists: true, $ne: null }
    setParameter(condition)
    refetch({
      contentParams: {
        ...condition,
        order_by: [
          {
            field: 'updated_time',
            sort: 'desc',
          },
        ],
      },
      limit: pageInfo.limit,
      page: 1,
    })
  }
  const handleChangePage = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({ ...pageInfo, page })
    console.log()
    refetch({
      contentParams: {
        ...parameter,
        order_by: [
          {
            field: 'updated_time',
            sort: 'desc',
          },
        ],
        // has_video: false // for test has_video
      },
      limit: pageInfo.limit,
      page,
    })
  }
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }

  return (
    <Fragment>
      {/* <Grid container style={{ marginBottom: '8px' }}>
        <div className={classes.grow} />
        <Grid item>
         
        </Grid>
      </Grid> */}

      <>
        <Paper className={classes.paper}>
          <SearchForm
            onSubmit={handleSubmit}
            size="small"
            actionButton={
              addContentBtn ? (
                <ButtonLink
                  className={classes.button_add}
                  to="/content/edit"
                  startIcon={<Add />}
                  style={{ order: -1 }}
                  variant="contained"
                  color="primary"
                >
                  เพิ่ม Content
                </ButtonLink>
              ) : (
                ''
              )
            }
            storytellingButton={
              <Button
                color="primary"
                variant={isStorytelling ? 'contained' : 'outlined'}
                startIcon={<MenuBook style={{ color: isStorytelling ? "" : "inherit" }}/>}
                onClick={() => {
                  setIsStoryTelling((prev) => !prev)
                  handleStorytelling(!isStorytelling)
                }}
              >
                Storytelling
              </Button>
            }
          />
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    รหัส
                  </TableCell>
                  <TableCell style={{ minWidth: 400 }} align="left">
                    หัวเรื่อง
                  </TableCell>
                  <TableCell align="left">{wording['topic']}</TableCell>
                  <TableCell align="left">{wording['category']}</TableCell>
                  <TableCell style={{ width: 100 }} align="left">
                    สถานะ
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }} align="left">
                    แก้ไขล่าสุด
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }} align="left">
                    ผู้แก้ไขล่าสุด
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }} align="left">
                    เวลาเผยแพร่
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loading />
              ) : (
                <TableBody>
                  {data?.ContentDrafts &&
                    data?.ContentDrafts.data.map((item: IContent) => (
                      <TableRow hover>
                        <TableCell component="td" scope="row">
                          <Link
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push(`/content/edit/${item.id}`)
                            }}
                          >
                            {item.id}
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          <Link
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push(`/content/edit/${item.id}`)
                            }}
                          >
                            {item.title}
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          {item?.topic?.map((item) => item.label).join(', ')}
                        </TableCell>
                        <TableCell align="left">
                          {item?.categories
                            ?.map((item) => item.label)
                            .join(', ')}
                        </TableCell>
                        <TableCell align="left">
                          {CONTENT.STATUS_MAPPING[item.status]}
                        </TableCell>
                        <TableCell align="left">{`${item.updated_time}`}</TableCell>
                        <TableCell align="left">
                          {item.created_by &&
                            `${
                              item.created_by.first_name +
                              ' ' +
                              item.created_by.last_name
                            }`}
                        </TableCell>
                        <TableCell align="left">
                          {`${item.publish_time}`}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Grid container>
            <div className={classes.grow} />
            <Grid item>
              <Pagination
                onChange={handleChangePage}
                page={pageInfo.page}
                className={classes.pagination}
                count={Math.ceil(data?.ContentDrafts?.total / pageInfo.limit)}
                showFirstButton
                showLastButton
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    </Fragment>
  )
}

export default ListContent
