import React, { ReactElement, useState } from 'react'
import { QUERY_GET_ALL_TOPICS } from '@Api/topic_api'
import { useQuery } from '@apollo/client'
import Loading from '@Components/Loading'
import { Tabs, Tab } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
import { ITopic } from '@Types/topic_type'
import Detail from './detail'
interface StyledTabProps {
  label?: string
  disabled?: boolean
  children?: React.ReactNode
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  padding: {
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  header_container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  status_wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginInline: theme.spacing(1),
  },
}))

function SuggestionTag(): ReactElement {
  const classes = useStyles()
  const [tabNow, setTabNow] = useState(0)
  const { data, loading } = useQuery(QUERY_GET_ALL_TOPICS)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNow(newValue)
  }
  // permission
  if (loading) return <Loading />
  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs onChange={handleChange} value={tabNow}>
          {data?.Topics &&
            data.Topics.map((topic: ITopic) => (
              <AntTab key={topic._id} label={topic.label} />
            ))}
        </AntTabs>
        <div className={classes.padding}>
          {data?.Topics && (
            <>
              <Detail topic={data?.Topics[tabNow]} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    position: 'relative',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      marginRight: theme.spacing(3),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => (
  <Tab disableRipple {...props}>
    {props.children}
  </Tab>
))

export default SuggestionTag
