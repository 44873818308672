import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import React, { ReactElement, useState } from 'react'
import { authorType } from '@Types/author_type'
import { styles } from './form.style'
import { isEmpty } from 'lodash'

interface FormProps {
  title: string
  onSubmit: (data: authorType) => Promise<number | null>
  defaultData?: authorType
  avatar?: () => React.ReactNode
}
const useStyles = makeStyles(styles)

export default function Form({
  title,
  onSubmit,
  defaultData,
  avatar,
}: FormProps): ReactElement {
  const classes = useStyles()
  const [validate, setValidate] = useState({
    value: 'slug name',
    message: 'Please input slug name',
    status: false,
  })
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      first_name: { value: string }
      last_name: { value: string }
      nick_name: { value: string }
      email: { value: string }
      bank_account: { value: string }
      telephone: { value: string }
      authorname: { value: string }
      password: { value: string }
      team: { value: string }
      is_active: { value: string }
      address: { value: string }
      description: { value: string }
      slug_name: { value: string }
    }
    const obj = {
      first_name: target.first_name.value,
      last_name: target.last_name.value,
      nick_name: target.nick_name.value,
      email: target.email.value,
      telephone: target.telephone.value,
      is_active: target.is_active.value,
      description: target.description.value,
      slug_name: target.slug_name.value,
    } as authorType
   
    if (isEmpty(obj.slug_name.trim())) {
      setValidate({
        ...validate,
        status: true,
        message: 'Please input slug name',
      })
    } else if (!/^[a-z0-9!._-]*$/g.test(obj.slug_name))
      setValidate({
        ...validate,
        status: true,
        message: 'Please input a-z 0-9 . - _',
      })
    else {
      const error = await onSubmit(obj)
      if (error === 400) {
        setValidate({
          ...validate,
          status: true,
          message: 'slug name already exists',
        })
      } else {
        setValidate({
          ...validate,
          status: true,
          message: '',
        })
      }
    }
  }

  return (
    <>
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{title}</Typography>
            <div className={classes.grow} />
            <Button
              className={classes.button_save}
              startIcon={<SaveIcon />}
              variant="outlined"
              type="submit"
            >
              บันทึกข้อมูล
            </Button>
          </Box>
          <Divider className={classes.divider} />
          {avatar && (
            <Grid container justify="center" spacing={2}>
              <Grid item>{avatar()}</Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            {/* <Grid item xs={6} className={classes.section}> */}
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="first_name"
                label="First Name"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.first_name}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="last_name"
                label="Last Name"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.last_name}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="nick_name"
                label="Nick Name"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.nick_name}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="email"
                label="Email"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.email}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="telephone"
                label="Telephone"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.telephone}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl
                // variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <InputLabel>สถานะผู้ใช้งาน</InputLabel>
                <Select
                  // value={age}
                  // onChange={handleChange}
                  defaultValue={defaultData?.is_active || 1}
                  label="สถานะผู้ใช้งาน"
                  name="is_active"
                  native
                >
                  <option value={1}>ใช้งาน</option>
                  <option value={0}>ไม่ได้ใช้งาน</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="description"
                label="Description"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.description}
                multiline
                // rows={4}
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="slug_name"
                label="Slug Name"
                size="small"
                helperText={validate.status ? validate.message : ''}
                // variant="outlined"
                error={validate.status}
                fullWidth
                defaultValue={defaultData?.slug_name}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  )
}
