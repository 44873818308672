import { useMutation } from '@apollo/client'
import { Avatar, makeStyles } from '@material-ui/core'
import React, { ReactElement, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
  QUERY_GET_ALL_AUTHORS,
  MUTATION_ADD_AUTHOR,
  MUTATION_EDIT_AUTHOR,
} from '@Api/author_api'
import Form from '@Components/Author/Form'
import { styles } from '@Components/Author/form.style'
import useUpload from '@Hooks/useUpload'
import { authorType } from '@Types/author_type'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
// import { checkPermission } from "@Components/checkPermission"

const useStyles = makeStyles(styles)

export default function CreateAuthor(): ReactElement {
  const { checkPermission } = useAuth() as AuthContextType

  useEffect(() => {
    const checked = checkPermission('author_edit')
    if (!checked) {
      window.location.href = '/'
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles()
  const history = useHistory()
  const [imageProfile, setImageProfile] = useState<FileList>()
  const { onUpload } = useUpload()
  const [addAuthor] = useMutation(MUTATION_ADD_AUTHOR)
  const [editAuthor] = useMutation(MUTATION_EDIT_AUTHOR)
  const handleSubmit = async (obj: authorType): Promise<number | null> => {
    const { data } = await addAuthor({
      variables: obj,
      refetchQueries: [
        {
          query: QUERY_GET_ALL_AUTHORS,
        },
      ],
    })
    if (data.AddAuthor.statusCode === 200) {
      const _id = data.AddAuthor.data._id
      if (imageProfile) {
        const res_image = await onUpload(imageProfile, 'image_files', {
          ref_id: _id,
        })
        if (res_image.data?.status === 200) {
          editAuthor({
            variables: {
              ...obj,
              profile_image: res_image.data.data?.items[0]?.url,
              _id: _id,
            },
          })
        }
      }
      history.push('/author')
      return null
    } else {
      return data?.AddAuthor?.statusCode || 'error'
    }
  }
  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imgs = event.target.files
    if (imgs) setImageProfile(imgs)
  }
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        avatar={() => {
          return (
            <>
              <Avatar
                className={classes.avatar}
                src={
                  imageProfile
                    ? URL.createObjectURL(imageProfile[0])
                    : undefined
                }
              />
              <input
                type="file"
                onChange={handleChangeFile}
                className={classes.upload_profile}
              />
            </>
          )
        }}
        title="เพิ่มข้อมูลผู้เขียน"
      />
    </>
  )
}
