import { useMutation } from '@apollo/client'
import { Avatar, makeStyles } from '@material-ui/core'
import React, { ReactElement, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
  QUERY_GET_ALL_FOLLOWS,
  MUTATION_ADD_FOLLOW,
  MUTATION_EDIT_FOLLOW,
} from '@Api/follow_api'
import Form from '@Components/Follow/Form'
import { styles } from '@Components/Follow/form.style'
import useUpload from '@Hooks/useUpload'
import { followType } from '@Types/follow_type'
import { AuthContextType, useAuth } from '@Hooks/useAuth'

const useStyles = makeStyles(styles)

export default function CreateFollow(): ReactElement {
  const { checkPermission } = useAuth() as AuthContextType

  useEffect(() => {
    const checked = checkPermission('author_edit')
    if (!checked) {
      window.location.href = '/'
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles()
  const history = useHistory()
  const [imageProfile, setImageProfile] = useState<FileList>()
  const { onUpload } = useUpload()
  const [addFollow] = useMutation(MUTATION_ADD_FOLLOW)
  const [editFollow] = useMutation(MUTATION_EDIT_FOLLOW)
  const handleSubmit = async (obj: followType) => {
    
    const { data } = await addFollow({
                                      variables: obj,
                                      refetchQueries: [{ query: QUERY_GET_ALL_FOLLOWS }],
                                    })

    if (data.AddFollow.statusCode === 200) 
    {
      const _id = data.AddFollow.data._id
      if (imageProfile) {
        const res_image = await onUpload(imageProfile, 'image_files', { ref_id: _id, })

        if (res_image.data?.status === 200) {
          console.log('imageProfile ==>' + res_image.data.data?.items[0]?.url)

          let resEditFollow = await editFollow({
                                                  variables: {
                                                                ...obj,
                                                                cover: res_image.data.data?.items[0]?.url,
                                                                _id: _id,
                                                              },
                                                })
 
          if (resEditFollow.data.EditFollow.statusCode === 200) {
                window.location.href = '/follow';
                //history.push('/follow')
          }
          else  history.push('/follow')

        }
        else  history.push('/follow')

      }
      else    history.push('/follow')
    }
  }
  
  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imgs = event.target.files
    if (imgs) setImageProfile(imgs)
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        avatar={() => {
          return (
            <>
              <Avatar
                className={classes.avatar}
                src={
                  imageProfile
                    ? URL.createObjectURL(imageProfile[0])
                    : undefined
                }
              />
              <input
                type="file"
                onChange={handleChangeFile}
                className={classes.upload_profile}
              />
            </>
          )
        }}
        title="เพิ่มข้อมูล follow"
      />
    </>
  )

}
