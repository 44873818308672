import React, { useContext, useState } from 'react'
//Autocomplete
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles, TextField, Checkbox } from '@material-ui/core'
import {
  PodcastContext,
  PodcastContextType,
} from '../../context/PodcastContext'
import { styles } from '../styles'
import { useQuery } from '@apollo/client'
import { QUERY_GET_TOPIC_AND_CATEGORY_AND_CHANNEL } from '@Api/content_api'
import { ICategory } from '@Types/category_type'
import { ITopic } from '@Types/topic_type'
import { useGlobal } from '@Hooks/useGlobal'
import Loading from '@Components/Loading'
import { IChannel } from '@Types/channel_type'

const useStyles = makeStyles(styles)

function SettingThairathPlus() {
  const classes = useStyles()
  const { wording } = useGlobal()
  const { data, loading } = useQuery(QUERY_GET_TOPIC_AND_CATEGORY_AND_CHANNEL)
  const {
    podcast: [podcast],
    handleSelectChange,
  } = useContext(PodcastContext) as PodcastContextType

  const setFormatData = (data: ITopic[] | ICategory[] | IChannel[] ) => {
    return data.map((item: any) => ({
      id: item?._id,
      value: item?.label,
    }))
  }

  const [channelSelected, setChannelSelected] = useState(
    setFormatData(podcast.channel),
  )

  if (loading) return <Loading />
  return (
    <>
      {/* Channel */}
      <Autocomplete
        onChange={(_, values) => {
          if (values) {
            handleSelectChange('channel', [values.id])
            setChannelSelected([values])
            
          } else {
            handleSelectChange('channel', [])
            setChannelSelected([])
          }
        }}
        size="small"
        options={data.Channels}
        value={channelSelected[0]}
        getOptionLabel={(option) => option.value}
        classes={{
          paper: classes.menu_item,
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={wording['channel']} />
        )}
      />
    </>
  )
}

export default SettingThairathPlus
