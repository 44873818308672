import { Button, Grid, IconButton } from '@material-ui/core'
import React, { ReactElement, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import {
  Add,
  Close,
  //CollectionsBookmarkRounded,
  Save,
} from '@material-ui/icons'
import {
  MUTATION_ADD_EDITOR_PICK_PODCAST,
  QUERY_GET_PODCAST_FOR_PICK,
  QUERY_GET_PODCAST_PICKED,
} from '@Api/editor_pick_podcast_api'
import { IPodcast } from '@Types/podcast_type'
import Loading from '@Components/Loading'
import isEmpty from 'lodash.isempty'
import { 
  IPodcastListItem, 
  //IPodcastLists 
} from '@Types/podcast_list_type'
import SearchForm, { FormSearchType } from '../Content/Widget/searchForm'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import { useSnackbar } from '@Hooks/useSnackbar'
import CardContent from '@Components/CardPodcast'
import DragAndDrop, { DragItem, DropItem } from '@Components/DragAndDrop'
import { PODCAST } from '@Libs/const'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
    img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    content_img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    title: {
      paddingLeft: theme.spacing(1),
    },
    sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 2,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    content_sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    count_view: {
      fontSize: theme.spacing(1.8),
      display: 'flex',
      alignItems: 'center',
    },
    icon_view: {
      fontSize: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
    },
    picked: {
      marginLeft: theme.spacing(2),
    },
    divider: {
      marginInline: theme.spacing(2),
    },
    success: {
      color: theme.palette.success.main,
    },
    button_save: {
      marginRight: theme.spacing(2),
    },
    list_item: {
      border: '1px solid #dfdfdf',
      borderRadius: '3px',
      marginBottom: '10px',
      boxShadow: '2px 2px 4px #e6e6e6',
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
)
interface DetailProps {
  topic: string
}

export default function Detail({ topic }: DetailProps): ReactElement {
  const { checkPermission } = useAuth() as AuthContextType
  const [saveAddBtn, setStateSaveAddBtn] = useState<boolean>(false)
  const [pageInfo, setPageInfo] = useState({ page: 1, limit: 10 })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [parameter, setParameter] = useState<FormSearchType>()
  const { onToggle } = useSnackbar()
  useEffect(() => {
    const checked = checkPermission('editor_view')
    const checkedEdit = checkPermission('editor_edit')
    if (!checked) {
      window.location.href = '/'
    }

    if (checkedEdit) {
      setStateSaveAddBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  const [selected, setSelected] = useState<IPodcast[]>([])
  const [dataExpired, setDateExpired] = useState<IPodcastListItem[]>([])

  const [save] = useMutation(MUTATION_ADD_EDITOR_PICK_PODCAST, {
    refetchQueries: [
      {
        query: QUERY_GET_PODCAST_PICKED,
        variables: {
          type: 'podcast',
          section: topic,
        },
      },
    ],
  })

  const { loading: loading_selected } = useQuery(QUERY_GET_PODCAST_PICKED, {
    variables: {
      ListParamsPodcast: {
        type: 'podcast',
        section: 'home',
      },
    },
    onCompleted: (data) => {
      console.log(data)
      setDateExpired(data.CmsListsPodcast[0].items)
      setSelected(
        data.CmsListsPodcast[0]?.items?.map(
          (item: IPodcastListItem) => item !== null && item.Podcast,
        ) ?? [],
      )
    },
  })

  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_PODCAST_FOR_PICK,
    {
      variables: {
        podcastParams: {
          // all: '',
          status: [PODCAST.STATUS.PUBLISH],
          order_by: [
            {
              field: 'updated_time',
              sort: 'desc',
            },
          ],
        },
        page: 1,
        limit: 30,
      },
      notifyOnNetworkStatusChange: true,
    },
  )

  const handleClickSave = async () => {
    const clean_data: IPodcastListItem[] = selected.map((item) => ({
      id: item.id,
      type: 'podcast',
      expire_time: item.expire_time,
      status: item.status
    }))

    const res = await save({
      variables: {
        type: 'podcast',
        section: topic,
        items: clean_data,
      },
    })
    if (res.data?.AddListPodcast?.statusCode === 200) {
      onToggle({ type: 'success', open: true, message: 'บันทึกข้อมูลสำเร็จ!' })
    } else {
      onToggle({
        type: 'error',
        open: true,
        message: 'ไม่สามารถบันทึกข้อมูลได้!',
      })
      console.log(`LOG: cannot save editor pick ---> `, res.data.message)
    }
  }
  const handleClickAdd = (podcast: IPodcast) => {
    if (podcast.status !== '30') {
      onToggle({
        type: 'warning',
        open: true,
        message: 'บทความนี้ไม่เป็น Publish!',
      })
      return
    }
    if (selected.findIndex((f) => f.id === podcast.id) !== -1) {
      onToggle({
        type: 'warning',
        open: true,
        message: 'คุณได้เลือกบทความนี้ไปแล้ว!',
      })
      return
    }
  
    setSelected([...selected, podcast])
  }

  const handleClickRemove = (podcast: IPodcast) => {
    const new_arr = selected.filter(
      (item) => item !== null && item.id !== podcast.id,
    )
    setSelected([...new_arr])
  }

  const handleSearchSubmit = (form_search: FormSearchType) => {
    setPageInfo({ ...pageInfo, page: 1 })
    setParameter(form_search)
    refetch({
      podcastParams: {
        ...form_search,
        // all: '',
        status: [PODCAST.STATUS.PUBLISH],
        order_by: [
          {
            field: 'publish_time',
            sort: 'desc',
          },
        ],
      },
      page: 1,
      limit: 30,
    })
  }

  if (error) return <>error :(</>
  return (
    <>
    {console.log(dataExpired)}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Grid container>
            <Grid item>
              <Typography className={classes.picked} variant="h6">
                เลือกแล้ว
              </Typography>
            </Grid>
            <div className={classes.grow} />
            <Grid item>
              {saveAddBtn ? (
                <Button
                  onClick={handleClickSave}
                  className={classes.button_save}
                  size="small"
                  startIcon={<Save className={classes.success} />}
                >
                  บันทึก
                </Button>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <Divider variant="fullWidth" className={classes.divider} />
          {loading_selected ? (
            <Loading />
          ) : (
            <DragAndDrop
              data={selected}
              onChange={(new_data) => {
                setSelected(new_data)
              }}
            >
              <List className={classes.root} dense>
                <DropItem droppableId="droppable">
                  {!isEmpty(selected) &&
                    selected.map(
                      (item: IPodcast, index: number) =>
                        !isEmpty(item) && 
                            <DragItem
                              index={index}
                              draggableId={item.id || `draggableId_${index}`}
                              key={item.id}
                              // key must have !!!!
                              // no key = bug
                            >
                              <CardContent
                                podcast={item}
                                expired={dataExpired[index]?.expired || false}
                                key={`${topic}_${item?._id}`}
                                action={() =>
                                  saveAddBtn && (
                                    <IconButton
                                      onClick={() => handleClickRemove(item)}
                                      size="small"
                                    >
                                      <Close color="error" />
                                    </IconButton>
                                  )
                                }
                              />
                            </DragItem>
                    )}
                </DropItem>
              </List>
            </DragAndDrop>
          )}
        </Grid>
        <Grid item xs={12} lg={1}></Grid>
        <Grid item xs={12} lg={7}>
          <Grid container xs={12}>
            <Grid item xs={3}>
              <Typography className={classes.picked} variant="h6">
                พ็อดคาสท์
              </Typography>
            </Grid>
            {/* <div className={classes.grow} /> */}
            <Grid item>
              <SearchForm
                onSubmit={handleSearchSubmit}
                display={{
                  status: false,
                }}
              />
            </Grid>
          </Grid>
          <List className={classes.root} dense>
            {loading ? (
              <Loading />
            ) : (
              <>
                {!isEmpty(data?.PodcastForPick?.data) &&
                  data?.PodcastForPick?.data.map(
                    (item: IPodcast, index: number) =>
                      !isEmpty(item) && (
                        <CardContent
                          podcast={item}
                          key={`${topic}_${item?._id}`}
                          expired={false}
                          action={() =>
                            saveAddBtn && (
                              <IconButton size="small">
                                <Add
                                  onClick={() => handleClickAdd(item)}
                                  className={classes.success}
                                />
                              </IconButton>
                            )
                          }
                        />
                      ),
                  )}
              </>
            )}
          </List>
        </Grid>
      </Grid>
    </>
  )
}
