import { IRule } from './index'

export const rules: Array<IRule> = [
  {
    name: 'Content',
    group: [
      {
        name: 'Content All (ดู , สร้าง , แก้ไข)',
        key: 'content',
        type: {
          view: true,
          edit: true,
        },
      },
      {
        name: 'Content Publish',
        key: 'content_publish',
        type: {
          view: false,
          edit: true,
        },
      },
      {
        name: 'Latest Pick',
        key: 'latest',
        type: {
          view: true,
          edit: true,
        },
      },
      {
        name: 'Panorama',
        key: 'mirror_panorama',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Category',
    group: [
      {
        name: 'Category All (ดู , สร้าง , แก้ไข)',
        key: 'category',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Author',
    group: [
      {
        name: 'Author All (ดู , สร้าง , แก้ไข)',
        key: 'author',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Product',
    group: [
      {
        name: 'Product All (ดู , สร้าง , แก้ไข)',
        key: 'mirror_product',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Admin',
    group: [
      {
        name: 'Admin all (ดู , สร้าง , แก้ไข)',
        key: 'admin',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'SEO',
    group: [
      {
        name: 'Seo Tag Suggestion (tag แนะนำ)',
        key: 'seo_tag_suggestion',
        type: {
          view: true,
          edit: true,
        },
      },
      {
        name: 'Meta Tag Pages',
        key: 'seo_tag_pages',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
]

export const getPermissionByRole = (role: string) => {
  switch (role) {
    case 'Editor':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'panorama_view',
        'panorama_edit',
        'category_view',
        'category_edit',
      ]
    case 'Editor Manager':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'panorama_view',
        'panorama_edit',
        'category_view',
        'category_edit',
      ]
    case 'Product':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'author_view',
        'author_edit',
        'seo_tag_suggestion_view',
        'seo_tag_suggestion_edit',
        'seo_tag_pages_view',
        'seo_tag_pages_edit',
        'panorama_view',
        'panorama_edit',
      ]
    case 'Admin':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'author_view',
        'author_edit',
        'mirror_product_view',
        'mirror_product_edit',
        'admin_view',
        'admin_edit',
        'seo_tag_suggestion_view',
        'seo_tag_suggestion_edit',
        'seo_tag_pages_view',
        'seo_tag_pages_edit',
        'panorama_view',
        'panorama_edit',
        'latest_view',
        'latest_edit',
        'mirror_panorama_view',
        'mirror_panorama_edit',
        'category_view',
        'category_edit',
      ]
    case 'SEO':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'seo_tag_suggestion_view',
        'seo_tag_suggestion_edit',
        'seo_tag_pages_view',
        'seo_tag_pages_edit',
      ]
    case 'Custom':
      return []
    default:
      return []
  }
}

const mirror_role = {
  rules,
  getPermissionByRole,
}
export default mirror_role
