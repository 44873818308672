import React, { Fragment, useState } from 'react'
// import CreateSeo from './Seo/create'
// import TopicImage from './TopicImage/create'
import { Tabs, Tab } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
interface StyledTabProps {
  label?: string
  disabled?: boolean
  chlidren?: React.ReactNode
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}))
const CreateTab = () => {
  const classes = useStyles()
  const [tabNow, setTabNow] = useState(0)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNow(newValue)
  }
  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.demo1}>
          <AntTabs
            value={tabNow}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <AntTab label="Seo" />
            <AntTab label="Topic Image" />
          </AntTabs>
          {/* {tabNow === 0 && <CreateSeo/>} */}
          {/* {tabNow === 1 && <TopicImage/>} */}
        </div>
      </div>
    </Fragment>
  )
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    position: 'relative',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      marginRight: theme.spacing(3),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => (
  <Tab disableRipple {...props}>
    {props.chlidren}
  </Tab>
))

export default CreateTab
