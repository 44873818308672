import { wordingType } from '@Types/wording_type'
const wording: wordingType = {
  app_name: 'Mirror CMS',
  favicon: 'mirror-icon-32x32.ico',
  topic: 'Menu',
  subtopic: 'Sub-Topic',
  category: 'Category',
  channel: 'Channel',
  content_page: {
    abstract: 'ประเภทเนื้อหา',
    summary: 'เนื้อหาอย่างย่อ',
  },
}
export default wording
