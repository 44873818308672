import { useEffect } from 'react'

import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import { QUERY_GET_ALL_FOLLOWS } from '@Api/follow_api'
import ButtonLink from '@Components/ButtonLink'
import LinkRouter from '@Components/LinkRouter'
import AddIcon from '@material-ui/icons/Add'
import { useQuery } from '@apollo/client'
import Loading from '@Components/Loading'
import { AuthContextType, useAuth } from '@Hooks/useAuth'

const columns: Array<MUIDataTableColumnDef> = [
  {
    name: '_id',
    label: '#',
    options: {
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex) => <>{dataIndex + 1}</>,
    },
  },
  {
    name: 'cover',
    label: 'Pictuer',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, { rowData }) => (
        <img src={value} alt={value} height="125" />
      ),
    },
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <LinkRouter to={`/follow/edit/${rowData[0]}`}>{value}</LinkRouter>
      ),
    },
  },
  {
    name: 'type',
    label: 'ประเภท',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'is_active',
    label: 'สถานะ',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => (
        <>{value === 'Y' ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</>
      ),
    },
  },
  {
    name: 'lastUpdateDate',
    label: 'Last Update',
    options: {
      filter: false,
      sort: true,
    },
  }
]

export default function Follow() {
  const { checkPermission } = useAuth() as AuthContextType

  useEffect(() => {
    const checked = checkPermission('author_view')
    if (!checked) {
      window.location.href = '/'
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const { loading, error, data } = useQuery(QUERY_GET_ALL_FOLLOWS)
  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <>
      <MUIDataTable
        title={'เรื่องที่ติดตามทั้งหมด'}
        columns={columns}
        data={data.Follows}
        options={{
          print: false,
          download: false,
          selectableRows: 'none',
          customToolbar: () => {
            return (
              <>
                <ButtonLink
                  to="/follow/create"
                  startIcon={<AddIcon />}
                  style={{ order: -1 }}
                  variant="outlined"
                >
                  Add Follow
                </ButtonLink>
              </>
            )
          },
          setTableProps: () => {
            return {
              size: 'small',
            }
          },
        }}
      />
    </>
  )
}
