import { ChangeEvent, useEffect, useState } from 'react'

import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import { QUERY_GET_ALL_MIRROR_PRODUCTS } from '@Api/mirror/product_api'
import {
  MUTATION_EDIT_WEB_CONFIG,
  QUERY_GET_WEB_CONFIG,
} from '@Api/web_config_api'
import ButtonLink from '@Components/ButtonLink'
import LinkRouter from '@Components/LinkRouter'
import AddIcon from '@material-ui/icons/Add'
import { useMutation, useQuery } from '@apollo/client'
import Loading from '@Components/Loading'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import { FormControlLabel, Grid, Typography } from '@material-ui/core'
import {
  mirrorProductCategories,
  mirrorProductStatus,
  mirrorProductStatusColor,
} from '@Libs/const/mirror/product'

import Switch from '@material-ui/core/Switch'

const columns: Array<MUIDataTableColumnDef> = [
  {
    name: '_id',
    label: '#',
    options: {
      filter: true,
      sort: true,
      customBodyRenderLite: (dataIndex) => <>{dataIndex + 1}</>,
    },
  },
  {
    name: 'name',
    label: 'ชื่อสินค้า',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <Typography style={{ width: 200 }} variant="body2" noWrap>
          <LinkRouter to={`/product/edit/${rowData[0]}`}>{value}</LinkRouter>
        </Typography>
      ),
    },
  },
  {
    name: 'product_url',
    label: 'ลิงก์สินค้า',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <Typography style={{ width: 150 }} variant="body2" noWrap>
          <a
            style={{
              textDecoration: 'none',
            }}
            rel="noreferrer"
            target="_blank"
            href={`${value}`}
          >
            {value}
          </a>
        </Typography>
      ),
    },
  },
  {
    name: 'status',
    label: 'สถานะ',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: string) => {
        return (
          <div style={{ color: mirrorProductStatusColor[value] }}>
            {mirrorProductStatus[value]}
          </div>
        )
      },
    },
  },
  {
    name: 'price',
    label: 'ราคา',
    options: {
      filter: true,
      sort: true,
      setCellHeaderProps: () => ({
        align: 'right',
      }),
      setCellProps: () => ({
        align: 'right',
      }),
    },
  },
  {
    name: 'discount_price',
    label: 'ราคาลด',
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({
        align: 'right',
      }),
    },
  },
  {
    name: 'type',
    label: 'Partner',
  },
  {
    name: 'category',
    label: 'หมวดหมู่',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (value) {
          return (
            <>{mirrorProductCategories.find((f) => f.value === value)?.label}</>
          )
        } else {
          return <></>
        }
      },
    },
  },
  {
    name: 'updated_time',
    label: 'แก้ไขล่าสุด',
    options: {
      filter: true,
      sort: true,
    },
  },
]
export default function MirrorProduct() {
  const { checkPermission } = useAuth() as AuthContextType
  const [checkbox, setCheckbox] = useState<boolean>(false)
  const [limit] = useState(100)
  useEffect(() => {
    const checked = checkPermission('mirror_product_view')
    if (!checked) {
      window.location.href = '/'
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const { loading, error, data } = useQuery(QUERY_GET_ALL_MIRROR_PRODUCTS, {
    variables: {
      page: 1,
      limit,
    },
  })

  useQuery(QUERY_GET_WEB_CONFIG, {
    onCompleted: ({ CmsWebConfig }) => {
      setCheckbox(CmsWebConfig?.hide_product_bar)
    },
  })

  const [editWebConfig] = useMutation(MUTATION_EDIT_WEB_CONFIG)
  const handleChecked = async (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    await editWebConfig({
      variables: {
        hide_product_bar: checked,
      },
    })
    setCheckbox(checked)
  }
  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <>
      <MUIDataTable
        title={
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" display="inline">
                สินค้าทั้งหมด
              </Typography>
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkbox}
                    onChange={handleChecked}
                    name="hide_product_bar"
                    color="primary"
                  />
                }
                label="ซ่อน Tabbar ทั้งหมด"
              />
            </Grid>
          </Grid>
        }
        columns={columns}
        data={data.CmsMirrorProducts}
        options={{
          print: false,
          download: false,
          selectableRows: 'none',
          customToolbar: () => {
            return (
              <>
                {checkPermission('mirror_product_edit') && (
                  <ButtonLink
                    to="/product/create"
                    startIcon={<AddIcon />}
                    style={{ order: -1 }}
                    variant="outlined"
                  >
                    เพิ่มสินค้า
                  </ButtonLink>
                )}
              </>
            )
          },
          setTableProps: () => {
            return {
              size: 'small',
            }
          },
          // onChangePage: (page) => {
          //   refetch({
          //     page,
          //     limit,
          //   })
          // },
        }}
      />
    </>
  )
}
