import { useEffect } from 'react'

import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import { QUERY_GET_ALL_CATEGORIES } from '@Api/category_api'
import ButtonLink from '@Components/ButtonLink'
import LinkRouter from '@Components/LinkRouter'
import AddIcon from '@material-ui/icons/Add'
import { useQuery } from '@apollo/client'
import Loading from '@Components/Loading'
import { AuthContextType, useAuth } from '@Hooks/useAuth'

const columns: Array<MUIDataTableColumnDef> = [
  {
    name: '_id',
    label: '#',
    options: {
      filter: true,
      sort: true,
      customBodyRenderLite: (dataIndex) => <>{dataIndex + 1}</>,
    },
  },
  {
    name: 'label',
    label: 'Label',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <LinkRouter to={`/category/edit/${rowData[0]}`}>{value}</LinkRouter>
      ),
    },
  },
  {
    name: 'value',
    label: 'Value',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <LinkRouter to={`/category/edit/${rowData[0]}`}>{value}</LinkRouter>
      ),
    },
  },
  {
    name: 'status',
    label: 'สถานะ',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        return <>{value === 10 ? 'ใช้งาน' : 'ไม่ได้ใช้งาน'}</>
      },
    },
  },
  {
    name: 'updated_at',
    label: 'Last Updated',
    options: {
      sort: true,
    },
  },
]
export default function Author() {
  const { checkPermission } = useAuth() as AuthContextType

  useEffect(() => {
    const checked = checkPermission('category_view')
    if (!checked) {
      window.location.href = '/'
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const { loading, error, data } = useQuery(QUERY_GET_ALL_CATEGORIES)
  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <>
      <MUIDataTable
        title={'ประเภททั้งหมด'}
        columns={columns}
        data={data.Categories}
        options={{
          print: false,
          download: false,
          selectableRows: 'none',
          customToolbar: () => {
            return (
              <>
                <ButtonLink
                  to="/category/edit"
                  startIcon={<AddIcon />}
                  style={{ order: -1 }}
                  variant="outlined"
                >
                  เพิ่มประเภท
                </ButtonLink>
              </>
            )
          },
          setTableProps: () => {
            return {
              size: 'small',
            }
          },
        }}
      />
    </>
  )
}
