import { Button, Grid, IconButton } from '@material-ui/core'
import React, { ReactElement, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Add, Close, Save } from '@material-ui/icons'
import {
  MUTATION_ADD_EDITOR_PICK,
  QUERY_GET_CONTENT_FOR_PICK,
  QUERY_GET_CONTENT_PICKED,
} from '@Api/editor_pick_api'
import { IContent } from '@Types/content_type'
import Loading from '@Components/Loading'
import isEmpty from 'lodash.isempty'
import { IListItem } from '@Types/lists_type'
import SearchForm, { FormSearchType } from '../../Content/Widget/searchForm'
import { ITopic } from '@Types/topic_type'
import clsx from 'clsx'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import { useSnackbar } from '@Hooks/useSnackbar'
import CardContent from '@Components/CardContent'
import DragAndDrop, { DragItem, DropItem } from '@Components/DragAndDrop'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    grow: {
      flexGrow: 1,
    },
    picked: {
      marginLeft: theme.spacing(2),
    },
    divider: {
      marginInline: theme.spacing(2),
    },
    success: {
      color: theme.palette.success.main,
    },
    button_save: {
      marginRight: theme.spacing(2),
    },
  }),
)
interface DetailProps {
  topic: ITopic
}

export default function Detail({ topic }: DetailProps): ReactElement {
  const MAX_CONTENT = topic.value === 'home' ? 10 : 1
  const classes = useStyles()
  const { checkPermission } = useAuth() as AuthContextType
  const [saveAddBtn, setStateSaveAddBtn] = useState<boolean>(false)
  const { onToggle } = useSnackbar()
  useEffect(() => {
    const checked = checkPermission('mirror_panorama_view')
    const checkedEdit = checkPermission('mirror_panorama_edit')
    if (!checked) {
      window.location.href = '/'
    }

    if (checkedEdit) {
      setStateSaveAddBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [listItems, setListItems] = useState<IListItem[]>([])
  const [save] = useMutation(MUTATION_ADD_EDITOR_PICK, {
    refetchQueries: [
      {
        query: QUERY_GET_CONTENT_PICKED,
        variables: {
          listParams: {
            type: 'mirror-panorama-pick',
            section: topic.value,
          },
        },
      },
    ],
  })

  const { loading: loading_selected } = useQuery(QUERY_GET_CONTENT_PICKED, {
    variables: {
      listParams: {
        type: 'mirror-panorama-pick',
        section: topic.value,
      },
    },
    onCompleted: (data) => {
      setListItems(data?.CmsList?.items || [])
    },
    fetchPolicy: 'no-cache',
  })

  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_CONTENT_FOR_PICK,
    {
      variables: {
        contentParams: {
          topic: [topic.value === 'home' ? 'all' : topic._id],
          order_by: [
            {
              field: 'publish_time',
              sort: 'desc',
            },
          ],
        },
        page: 1,
        limit: 30,
      },
      notifyOnNetworkStatusChange: true,
    },
  )

  const handleClickSave = async () => {
    const res = await save({
      variables: {
        type: 'mirror-panorama-pick',
        section: topic.value,
        items: listItems.map((item: IListItem) => ({
          type: item.type,
          id: item.id,
          extension: item.extension,
        })),
      },
    })
    if (res.data?.AddList?.statusCode === 200) {
      onToggle({
        type: 'success',
        open: true,
        message: 'บันทึกข้อมูลสำเร็จ!',
      })
    } else {
      onToggle({
        type: 'error',
        open: true,
        message: 'ไม่สามารถบันทึกข้อมูลได้!',
      })
      console.log(`LOG: cannot save editor pick ---> `, res.data.message)
    }
  }

  const handleClickAdd = (content: IContent) => {
    if (listItems.findIndex((f) => f.Content?.id === content.id) !== -1) {
      onToggle({
        type: 'warning',
        open: true,
        message: 'คุณได้เลือกบทความนี้ไปแล้ว!',
      })
      return
    }
    setListItems((prev) => [
      ...prev,
      {
        type: 'panorama',
        extension: {
          font1: '',
          font2: '',
          abstract: '',
        },
        id: content.id,
        Content: content,
      },
    ])
  }

  const handleClickRemove = (content?: IContent) => {
    if (!content) return
    setListItems((prev) => prev.filter((f) => f.id !== content.id))
  }

  const handleSearchSubmit = async (form_search: FormSearchType) => {
    await refetch({
      contentParams: {
        topic: [topic.value === 'home' ? 'all' : topic._id],
        ...form_search,
        order_by: [
          {
            field: 'publish_time',
            sort: 'desc',
          },
        ],
      },
      page: 1,
      limit: 30,
    })
  }

  if (error) return <>error :(</>
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <Grid container>
            <Grid item>
              <Typography className={classes.picked} variant="h6">
                เลือกแล้ว
              </Typography>
            </Grid>
            <div className={classes.grow} />
            {saveAddBtn ? (
              <Grid item>
                <Button
                  onClick={handleClickSave}
                  className={classes.button_save}
                  size="small"
                  startIcon={
                    <Save
                      color={
                        listItems.length > MAX_CONTENT ? 'disabled' : undefined
                      }
                      className={clsx(
                        listItems.length <= MAX_CONTENT && classes.success,
                      )}
                    />
                  }
                  disabled={listItems.length > MAX_CONTENT}
                >
                  บันทึก
                </Button>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Divider variant="fullWidth" className={classes.divider} />
          {loading_selected ? (
            <Loading />
          ) : (
            <DragAndDrop
              data={listItems}
              onChange={(new_data) => {
                setListItems(new_data)
              }}
            >
              <List className={classes.root} dense>
                <DropItem droppableId="droppable">
                  {listItems.map((item: IListItem, index: number) => (
                    <DragItem
                      index={index}
                      draggableId={item.Content?.id || `draggableId_${index}`}
                      key={item.Content?._id}
                      // key must have !!!!
                      // no key = bug
                    >
                      <CardContent
                        content={item.Content as IContent}
                        key={`${topic.value}_${item.Content?._id}`}
                        action={() =>
                          saveAddBtn && (
                            <IconButton
                              onClick={() => handleClickRemove(item.Content)}
                              size="small"
                            >
                              <Close color="error" />
                            </IconButton>
                          )
                        }
                      />
                    </DragItem>
                  ))}
                  <DragItem
                    index={9999}
                    draggableId={'text'}
                    key={'text'}
                    isDragDisabled
                  >
                    <Typography variant="body2" color="textSecondary">
                      {`หมายเหตุ: ใส่ได้สูงสุด ${MAX_CONTENT} บทความ`}
                    </Typography>
                  </DragItem>
                </DropItem>
              </List>
            </DragAndDrop>
          )}
        </Grid>
        <Grid item xs={12} lg={1}></Grid>
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={3}>
              <Typography className={classes.picked} variant="h6">
                บทความ
              </Typography>
            </Grid>
            {/* <div className={classes.grow} /> */}
            <Grid item>
              <SearchForm
                onSubmit={handleSearchSubmit}
                display={{
                  status: false,
                }}
              />
            </Grid>
          </Grid>

          <List className={classes.root} dense>
            {loading ? (
              <Loading />
            ) : (
              <>
                {!isEmpty(data?.ContentForPick) &&
                  data?.ContentForPick?.map(
                    (item: IContent, index: number) =>
                      item && (
                        <CardContent
                          content={item}
                          key={`${topic.value}_${item?._id}`}
                          action={() =>
                            saveAddBtn && (
                              <IconButton size="small">
                                <Add
                                  onClick={() => handleClickAdd(item)}
                                  className={classes.success}
                                />
                              </IconButton>
                            )
                          }
                        />
                      ),
                  )}
              </>
            )}
          </List>
        </Grid>
      </Grid>
    </>
  )
}
