import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    Paper,
    Select,
    TextField,
    Typography,
  } from '@material-ui/core'
  import SaveIcon from '@material-ui/icons/Save'
  import React, { ReactElement } from 'react'
  import { followType } from '@Types/follow_type'
  import { styles } from './form.style'
  
  interface FormProps {
    title: string
    onSubmit: (data: followType) => void
    defaultData?: followType
    avatar?: () => React.ReactNode
  }
  const useStyles = makeStyles(styles)
  
  export default function Form({
    title,
    onSubmit,
    defaultData,
    avatar,
  }: FormProps): ReactElement {
    const classes = useStyles()
  
    const handleSubmit = (e: React.SyntheticEvent) => {
      e.preventDefault()
      const target = e.target as typeof e.target & {
        title: { value: string }
        slug: { value: string }
        type: { value: string }
        description: { value: string }
        is_active: { value: string }
      }
      const obj = {
        title: target.title.value,
        slug: target.slug.value,
        type: target.type.value,
        description: target.description.value,
        is_active: target.is_active.value,
      } as followType
  
      onSubmit(obj)
    }
  
    return (
      <>
        <Paper className={classes.root}>
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6">{title}</Typography>
              <div className={classes.grow} />
              <Button
                className={classes.button_save}
                startIcon={<SaveIcon />}
                variant="outlined"
                type="submit"
              >
                บันทึกข้อมูล
              </Button>
            </Box>
            <Divider className={classes.divider} />
            {avatar && (
              <Grid container justify="center" spacing={2}>
                <Grid item>{avatar()}</Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              {/* <Grid item xs={6} className={classes.section}> */}
              <Grid item lg={4} md={6} xs={12}>
                <TextField
                  name="title"
                  label="Title"
                  size="small"
                  required
                  // variant="outlined"
                  fullWidth
                  defaultValue={defaultData?.title}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <TextField
                  name="slug"
                  label="Slug"
                  size="small"
                  // variant="outlined"
                  fullWidth
                  defaultValue={defaultData?.slug}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <TextField
                  name="description"
                  label="Description"
                  size="small"
                  // variant="outlined"
                  fullWidth
                  defaultValue={defaultData?.description}
                  multiline
                  // rows={4}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormControl
                  className={classes.formControl}
                  size="small"
                >
                  <InputLabel>ปรเภท</InputLabel>
                  <Select
                    defaultValue={defaultData?.type || 'content'}
                    label="ประเภท"
                    name="type"
                    native
                  >
                    <option value={"content"}>Content</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormControl
                  className={classes.formControl}
                  size="small"
                >
                  <InputLabel>สถานะผู้ใช้งาน</InputLabel>
                  <Select
                    defaultValue={defaultData?.is_active || 'Y'}
                    label="สถานะการใช้งาน"
                    name="is_active"
                    native
                  >
                    <option value={"Y"}>ใช้งาน</option>
                    <option value={"N"}>ไม่ได้ใช้งาน</option>
                  </Select>
                </FormControl>
              </Grid>

            </Grid>
          </form>
        </Paper>
      </>
    )
  }
  