import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

export const styles: Styles<Theme, {}, string> = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    padding: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  button_save: {},
  formControl: {
    minWidth: '100%',
  },
  list: {
    width: '99%',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
  },
  section: {
    '& > *': {
      marginBlock: theme.spacing(1),
    },
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  upload_profile:{
    marginTop:theme.spacing(2)
  },
  recommended:{
    color: '#FA0000',
    display: 'block'
  }
})
