import { gql } from '@apollo/client'

export const QUERY_GET_CATEGORY = gql`
  query ($params: CategoryParams!) {
    Category(params: $params) {
      _id
      value
      label
      status
      updated_at(format: "DD/MM/YYYY HH:mm:ss")
    }
  }
`

export const QUERY_GET_ALL_CATEGORIES = gql`
  query ($params: CategoryParams, $order_by: CategoryOrderBy) {
    Categories(params: $params, order_by: $order_by) {
      _id
      value
      label
      status
      updated_at(format: "DD/MM/YYYY HH:mm:ss")
    }
  }
`

// export const QUERY_GET_ALL_CATEGORIES_FOR_AUTOCOMPLETE = gql`
//   query ($params: CategoryParams) {
//     Categories(params: $params) {
//       id: _id
//       value: label
//     }
//   }
// `

export const MUTATION_ADD_CATEGORY = gql`
  mutation ($label: String, $value: String, $status: Int) {
    AddCategory(label: $label, value: $value, status: $status) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_CATEGORY = gql`
  mutation ($_id: ID, $label: String, $value: String, $status: Int) {
    EditCategory(_id: $_id, label: $label, value: $value, status: $status) {
      statusCode
      message
      data
    }
  }
`
