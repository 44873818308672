import { gql } from '@apollo/client'

export const QUERY_GET_PODCAST_FOR_PICK = gql`
  query PodcastForPick($podcastParams: PodcastParams, $page: Int, $limit: Int) {
    PodcastForPick(params: $podcastParams, page: $page, limit: $limit) {
      total
      data {
        id
        title
        widgets {
          layout
          data {
            title
            abstract
            description
            thumbnails
          }
        }
        podbean_url
        podbean_id
        images
        status
        author {
          _id
          first_name
          last_name
        }
        premium
        topic {
          _id
          value
          label
        }
        categories {
          _id
          value
          label
        }
        channel {
          _id
          value
          label
        }
        tags
        publish_time
        created_time
        expire_time
        count_view
        summary
        created_by {
          first_name
          last_name
          nick_name
        }
        updated_time
        share
        cover
      }
    }
  }
`
export const QUERY_GET_PODCAST_PICKED = gql`
  query CmsListsPodcast($podcastListParams: ListParamsPodcast) {
    CmsListsPodcast(lists: $podcastListParams) {
       type
      section
      items {
        type
        id
        expire_time
        expired
        status
        Podcast {
          title
          cover  
          id
          count_view
          status
        }
      }
    }
  }
`

export const MUTATION_ADD_EDITOR_PICK_PODCAST = gql`
  mutation addListPodcast(
    $type: String!
    $section: String!
    $items: [ListPodcastItemInput]!
  ) {
    AddListPodcast(section: $section, type: $type, items: $items) {
      statusCode
      message
    }
  }
`
