import { Fragment, useContext, useState, useEffect, } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  // Grid,
  Typography,
  // Table,
  // TableBody,
  // TableCell,
  // TableContainer,
  // TableHead,
  // TableRow,
  // Link,
  FormControlLabel,
  Button,
  Switch
} from '@material-ui/core'
import Newmodule from './addnewmodule'
import TemplateTimeline from './template_timeline'
import { ContentContext, ContentContextType } from '../context/ContentContext'
import { QUERY_GET_CONTENT_BY_ID, MUTATION_EDIT_CONTENT } from '@Api/content_api'
import { MUTATION_GEN_USER_TOKEN_STORYTELLING } from '@Api/user_api'
import { IWidget, IWidgetData } from '@Types/content_type'
import { useAuth, getTokenUser } from '@Hooks/useAuth'
import { useGlobal } from '@Hooks/useGlobal'

export default function Storytelling() {
  const { 
    content: [content],
    handleDeleteModule,
    handleDeleteSubModule,
  } = useContext(ContentContext) as ContentContextType
  const { user } = useAuth()
  const user_token = getTokenUser()
  const { data } = useQuery(QUERY_GET_CONTENT_BY_ID, { variables: { id: content.id }, notifyOnNetworkStatusChange: true })
  const [ genUserTokenStoryTelling ] = useMutation(MUTATION_GEN_USER_TOKEN_STORYTELLING)
  const [ editContent ] = useMutation(MUTATION_EDIT_CONTENT)
  const [isStoryTelling, setIsStoryTelling] = useState<boolean>(content?.is_storytelling || false)

  /** 
    * 0:list of module, 
    * 1:list of detail in module, 
    * 2:add timeline template, 
    * 6:create new module 
    **/
  const [msPage, setMSPage]           = useState<Number>(0)
  const [templateId, setTemplateId]   = useState<Number>(-1)
  const [contentId, setContentId]     = useState<String>('')
  const [moduleNo, setModuleNo]       = useState<Number>(-1)
  const [layoutName, setLayoutName]   = useState<String>('')
  const [moduleOId, setModuleOId]     = useState<String>('')
  const [statusModify, setStatusModify] = useState<string>('modify')
  
  const openListOfModule = (moduleNo: Number, layout: String, oid: String) => {
    setMSPage(1)
    setModuleNo(moduleNo)
    setLayoutName(layout)
    setModuleOId(oid)
  }

  const backToPrevious = (currentPages: Number) => {
    let backToPage: Number = Number(currentPages) - 1
    
    if(backToPage === 0) {
      setModuleNo(-1)
    }

    setMSPage(backToPage)
  }

  const editInfoInTemplate = (subModuleNo: Number) => {
    setTemplateId(subModuleNo)
    backToPrevious(3)
  }

  const deleteInfoInTemplate = (oid: string, subModuleNo: Number) => {
    let SMNO: number = parseInt(subModuleNo.toString())
    handleDeleteSubModule(oid, SMNO)
  }

  const addNewSubModule = () => {
    setStatusModify('add')
    backToPrevious(3)
  }

  const deleteModule = async (oid: string) => {
    //let nwData = 
    handleDeleteModule(oid)
    //data.ContentDraft = nwData
  }

  useEffect(() => {
    if(content.id) {
      setContentId(content.id)
    }
  }, [msPage])


  const fmt = (datetime:any, format = 'YYYY-MM-DD hh:mm:ss') => {
    if(datetime) {
      const dateTimeStr = datetime.toString().substring(0, (datetime.toString().length - 5));
    // const pad2 = (n) => n.toString().padStart(2, '0');
   
      const map = {
        YYYY: dateTimeStr.substring(0, 4),
        MM: dateTimeStr.substring(5, 7),
        DD: dateTimeStr.substring(8, 10),
        hh: dateTimeStr.substring(11, 13),
        mm: dateTimeStr.substring(14, 16),
        ss: dateTimeStr.substring(17, 19)
      };

      return Object.entries(map).reduce((prev, entry) => prev.replace(...entry), format);
    }
    else { return datetime }
    
  }

  useEffect(() => {
    console.log(content);
  }, [content])

  const editStoryTelling = async () => {
    const url = `${generateUrl()}?token=${content?.token}`
    const response = await genUserTokenStoryTelling({variables: {
      _id: user._id,
      user_id: user._id,
      username: user.username ? user.username : user.first_name,
      user_token: user_token,
      content_id: content.id,
      preview_url: url
    }})
    const token = response.data?.GenUserTokenStoryTelling?.token
    if (response.data?.GenUserTokenStoryTelling?.statusCode === 200 && token) {
      window.open(
        `${process.env.REACT_APP_STORYTELLING_URL}/layout/trplus/${contentId}?token=${token}`,
        '_self'
      );
    }
  }

  const { theme_name } = useGlobal()
  const generateUrl = () => {
    const base_url = process.env.REACT_APP_TR_PLUS
    const topic = content?.topic[0]?.value || content?.topic[0]
    if (theme_name === 'thairath-plus') {
      return `${base_url}/topic/${topic}/${content?.id}`
    } else if (theme_name === 'mirror') {
      return `${base_url}/${topic}/${content?.id}`
    } else return base_url
  }

  const handleStorytellingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsStoryTelling(event.target.checked)
    editContent({variables: {
      id: content.id,
      is_storytelling: event.target.checked
    }})
  };

  return (
    <Fragment>
      <br/>
      <Typography variant="h3" align='center'>STORYTELLING</Typography>
      <br/>
      <FormControlLabel
          control={
            <Switch checked={isStoryTelling} onChange={handleStorytellingChange} name="is_storytelling" />
          }
          label={`${isStoryTelling ? "on" : "off"}`}
        />
      <Button onClick={editStoryTelling} variant="outlined">Edit Storytelling</Button>
    </Fragment>
  )
}

// { content && content?.widgets ?
//   <>
//     {msPage === 2 && <TemplateTimeline previous={backToPrevious} moduleInfo={[templateId, moduleOId, moduleNo, statusModify]} />} 
//     {msPage === 6 && <Newmodule previous={backToPrevious} />}
//     {msPage === 1 && 
//       <>
//         {/* Section list of detail Module Pages (show detail in each module) */}
//         <Grid container spacing={1}>
//           <Grid container item xs={3}>
//               <Button 
//                 variant="contained" 
//                 color="primary" 
//                 onClick={() => backToPrevious(msPage)}>BACK</Button>
//           </Grid>
//           <Grid container item xs={6}>.</Grid>
//           <Grid container item xs={3}>
//               <Button 
//                 variant="contained" 
//                 color="primary"
//                 onClick={() => addNewSubModule()}>ADD</Button>
//           </Grid>
//         </Grid>
//         <br />
//         <TableContainer>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell style={{ minWidth: 400 }} align="left">Topic</TableCell>
//                 <TableCell align="center">category</TableCell>
//                 <TableCell style={{ minWidth: 170 }} align="center">แก้ไขล่าสุด</TableCell>
//                 <TableCell align="center">DELETE</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//             {content.widgets.map((item: IWidget, k: Number) => (
//               ( k === moduleNo &&
//                 item.data && 
//                 item.data.map((detail: IWidgetData, kloop: Number) => (
//                   <TableRow hover key={'SubModule_' + kloop}>
//                     <TableCell align="left">
//                       <Link style={{ cursor: 'pointer' }} onClick={() => editInfoInTemplate(kloop)}>{detail.title}</Link>
//                     </TableCell>
//                     <TableCell align="center">{layoutName}</TableCell>
//                     <TableCell align="center">{fmt(detail.updated_time)}</TableCell>
//                     <TableCell align="center">
//                       <Link style={{ cursor: 'pointer' }} onClick={() => deleteInfoInTemplate(item._id, kloop)}>DELETE</Link>
//                     </TableCell>
//                   </TableRow>
//                 ))
//               )
//             ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </>
//     }
//     { msPage === 0 &&
//       <>
//         {/* show list of Module in Storytelling */}
//         <Grid container spacing={1}>
//           <Grid container item xs={9}>.</Grid>
//           <Grid container item xs={3}>
//               <Button variant="contained" color="primary" onClick={() => setMSPage(6)}>+ เพิ่ม Module</Button>
//           </Grid>
//         </Grid>
//         <br />
//         <TableContainer>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell style={{ minWidth: 400 }} align="left">Module No.</TableCell>
//                 <TableCell align="center">category</TableCell>
//                 <TableCell align="center">.</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//             {content.widgets.map((item: IWidget, key: Number) => (
//               (item.layout !== 'default') && (
//                 <TableRow hover>
//                   <TableCell align="left">
//                     <Link style={{ cursor: 'pointer' }} onClick={() => openListOfModule(key, item.layout, item._id)}>
//                       {`Module - ${key}`}
//                     </Link>
//                   </TableCell>
//                   <TableCell align="center">{`${item.layout}`}</TableCell>
//                   <TableCell align="center">
//                     <Link style={{ cursor: 'pointer' }} onClick={() => deleteModule(item._id)}>DELETE</Link>
//                   </TableCell>
//                 </TableRow>
//               )
//             ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </>
//     }
//   </>
// :
//   <>
//     {/* default or init of Module in Storytelling */}
//     <Grid container spacing={1}>
//       <Grid container item xs={9}>.</Grid>
//       <Grid container item xs={3}>
//           <Button 
//             variant="contained" 
//             color="primary"
//             onClick={() => setMSPage(6)}>+ เพิ่ม Module</Button>
//       </Grid>
//     </Grid>
//     <br />
//     <TableContainer>
//       <Table>
//         <TableBody>
//           <TableRow hover>
//             <TableCell align="center">NONE</TableCell>
//           </TableRow>
//         </TableBody>
//       </Table>
//     </TableContainer>
//   </>
// }