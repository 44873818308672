import { ListItemSecondaryAction } from '@material-ui/core'
import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { IPodcast } from '@Types/podcast_type'
import isEmpty from 'lodash.isempty'
//import { IPodcastLists } from '@Types/podcast_list_type'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    content_img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    title: {
      paddingLeft: theme.spacing(1),
    },
    expired_text: {
      fontWeight: 'bold',
      fontSize: 'large',
      textAlign: 'center'
    },
    sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 2,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    content_sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    count_view: {
      fontSize: theme.spacing(1.8),
      display: 'flex',
      alignItems: 'center',
    },
    icon_view: {
      fontSize: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    list_item: {
      border: '1px solid #dfdfdf',
      borderRadius: '3px',
      marginBottom: theme.spacing(2),
      boxShadow: '2px 2px 4px #e6e6e6',
      background: 'white',
    },
    list_item_expired: {
      border: '1px solid 	#f87c7c',
      borderRadius: '3px',
      marginBottom: theme.spacing(2),
      boxShadow: '2px 2px 4px #e6e6e6',
      background: '#f8baba',
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
)
interface Props {
  podcast: IPodcast
  expired: boolean
  action?: () => React.ReactNode
}

const CardPodcast: React.FC<Props> = ({ podcast, action, expired }: Props) => {

  console.log(expired)
  const classes = useStyles()
  return (
    <ListItem className={expired ? classes.list_item_expired  : classes.list_item} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          src={!isEmpty(podcast?.cover) ? podcast?.cover : undefined}
          className={classes.img}
          imgProps={{
            draggable: false,
          }}
        />
      </ListItemAvatar>
      <ListItemText
        className={classes.title}
        primary={
          <React.Fragment>
            <a
              href={`/podcast/edit/${podcast?.id}`}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {podcast?.id} - {podcast?.title}
            </a>
          </React.Fragment>
        }
        primaryTypographyProps={{
          noWrap: true,
        }}
        secondary={
          <React.Fragment>
            <Typography
              variant="body2"
              className={classes.sub_title}
              color="textSecondary"
              component="div"
            >
              {podcast?.summary}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.count_view}
              color="primary"
              component="div"
              noWrap
            >
              <VisibilityIcon className={classes.icon_view} color="primary" />
              {podcast?.count_view?.toLocaleString()}
            </Typography>
            {expired && 
              <Typography className={classes.expired_text} >
                 ไม่ได้เผยแพร่
              </Typography>}
          </React.Fragment>
        }
      />
      {action && <ListItemSecondaryAction>{action()}</ListItemSecondaryAction>}
    </ListItem>
  )
}

export default CardPodcast
