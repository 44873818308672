import { Button, Grid, IconButton } from '@material-ui/core'
import React, { ReactElement, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Add, Close, Save } from '@material-ui/icons'
import {
  QUERY_GET_VIDEO_FOR_PICK,
  QUERY_GET_VIDEO_FOR_PICKED,
  MUTATION_ADD_VIDEO_EDITOR_PICK,
} from '@Api/editor_pick_video_api'
import { IVideo } from '@Types/video_type'
import Loading from '@Components/Loading'
import isEmpty from 'lodash.isempty'
import {
  IVideoListItem,
  //IVideoLists
} from '@Types/video_list_type'
import SearchForm, { FormSearchVideoType } from '../Content/Widget/searchForm'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import { useSnackbar } from '@Hooks/useSnackbar'
import CardVideo from '@Components/CardVideo'
import DragAndDrop, { DragItem, DropItem } from '@Components/DragAndDrop'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
    img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    content_img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    title: {
      paddingLeft: theme.spacing(1),
    },
    sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 2,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    content_sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    count_view: {
      fontSize: theme.spacing(1.8),
      display: 'flex',
      alignItems: 'center',
    },
    icon_view: {
      fontSize: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
    },
    picked: {
      marginLeft: theme.spacing(2),
    },
    divider: {
      marginInline: theme.spacing(2),
    },
    success: {
      color: theme.palette.success.main,
    },
    button_save: {
      marginRight: theme.spacing(2),
    },
    list_item: {
      border: '1px solid #dfdfdf',
      borderRadius: '3px',
      marginBottom: '10px',
      boxShadow: '2px 2px 4px #e6e6e6',
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
)
interface DetailProps {
  topic: string
}

export default function Detail({ topic }: DetailProps): ReactElement {
  const { checkPermission } = useAuth() as AuthContextType
  const [saveAddBtn, setStateSaveAddBtn] = useState<boolean>(false)
  const { onToggle } = useSnackbar()
  useEffect(() => {
    const checked = checkPermission('editor_view')
    const checkedEdit = checkPermission('editor_edit')
    if (!checked) {
      window.location.href = '/'
    }

    if (checkedEdit) {
      setStateSaveAddBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  const [selected, setSelected] = useState<IVideo[]>([])

  const [save] = useMutation(MUTATION_ADD_VIDEO_EDITOR_PICK, {
    refetchQueries: [
      {
        query: QUERY_GET_VIDEO_FOR_PICKED,
        variables: {
          params: {
            type: 'video-pick',
            section: topic,
          },
        },
      },
    ],
  })

  const { loading: loading_selected } = useQuery(QUERY_GET_VIDEO_FOR_PICKED, {
    variables: {
      params: {
        type: 'video-pick',
        section: 'home',
      },
    },

    onCompleted: (data) => {
      setSelected(
        data?.CmsListVideo?.items?.map((item: IVideoListItem) => item.Video) ??
          [],
      )
    },
  })

  const { data, loading, error, refetch } = useQuery(QUERY_GET_VIDEO_FOR_PICK, {
    variables: {
      videoParams: {
        order_by: [
          {
            field: 'publish_time',
            sort: 'desc',
          },
        ],
        content_type: 'content',
      },
      page_info: 1,
      limit: 30,
    },
    notifyOnNetworkStatusChange: true,
  })

  const handleClickSave = async () => {
    const clean_data: IVideoListItem[] = selected.map((item) => ({
      id: item.id,
      type: 'video',
    }))

    const res = await save({
      variables: {
        type: 'video-pick',
        section: topic,
        items: clean_data,
      },
    })

    if (res.data?.AddListVideo?.statusCode === 200) {
      onToggle({ type: 'success', open: true, message: 'บันทึกข้อมูลสำเร็จ!' })
    } else {
      onToggle({
        type: 'error',
        open: true,
        message: 'ไม่สามารถบันทึกข้อมูลได้!',
      })
      console.log(`LOG: cannot save editor pick ---> `, res.data.message)
    }
  }

  const handleClickAdd = (video: IVideo) => {
    if (selected.findIndex((f) => f.id === video.id) !== -1) {
      onToggle({
        type: 'warning',
        open: true,
        message: 'คุณได้เลือกบทความนี้ไปแล้ว!',
      })
      return
    }
    setSelected([...selected, video])
  }

  const handleClickRemove = (video: IVideo) => {
    const new_arr = selected.filter((item) => item.id !== video.id)
    setSelected([...new_arr])
  }

  const handleSearchSubmit = async (form_search: FormSearchVideoType) => {
    await refetch({
      videoParams: {
        topic: [topic === 'home' ? 'all' : topic],
        ...form_search,
        order_by: [
          {
            field: 'publish_time',
            sort: 'desc',
          },
        ],
        content_type: 'content',
      },
      page_info: 1,
      limit: 30,
    })
  }

  if (error) return <>error :(</>
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Grid container>
            <Grid item>
              <Typography className={classes.picked} variant="h6">
                เลือกแล้ว
              </Typography>
            </Grid>
            <div className={classes.grow} />
            <Grid item>
              {saveAddBtn ? (
                <Button
                  onClick={handleClickSave}
                  className={classes.button_save}
                  size="small"
                  startIcon={<Save className={classes.success} />}
                >
                  บันทึก
                </Button>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <Divider variant="fullWidth" className={classes.divider} />
          {loading_selected ? (
            <Loading />
          ) : (
            <DragAndDrop
              data={selected}
              onChange={(new_data) => {
                setSelected(new_data)
              }}
            >
              <List className={classes.root} dense>
                <DropItem droppableId="droppable">
                  {!isEmpty(selected) &&
                    selected.map((item: IVideo, index: number) => (
                      <DragItem
                        index={index}
                        draggableId={item.id || `draggableId_${index}`}
                        key={item.id}
                        // key must have !!!!
                        // no key = bug
                      >
                        <CardVideo
                          video={item}
                          key={`${topic}_${item?._id}`}
                          action={() =>
                            saveAddBtn && (
                              <IconButton
                                onClick={() => handleClickRemove(item)}
                                size="small"
                              >
                                <Close color="error" />
                              </IconButton>
                            )
                          }
                        />
                      </DragItem>
                    ))}
                </DropItem>
              </List>
            </DragAndDrop>
          )}
        </Grid>
        <Grid item xs={12} lg={1}></Grid>
        <Grid item xs={12} lg={7}>
          <Grid container xs={12}>
            <Grid item xs={3}>
              <Typography className={classes.picked} variant="h6">
                วิดีโอ
              </Typography>
            </Grid>
            {/* <div className={classes.grow} /> */}
            <Grid item>
              <SearchForm
                onSubmit={handleSearchSubmit}
                display={{
                  status: false,
                }}
              />
            </Grid>
          </Grid>

          <List className={classes.root} dense>
            {loading ? (
              <Loading />
            ) : (
              <>
                {!isEmpty(data?.Video) &&
                  data?.Video?.data.map((item: IVideo, index: number) => (
                    <CardVideo
                      video={item}
                      key={`${topic}_${item?._id}`}
                      action={() =>
                        saveAddBtn && (
                          <IconButton size="small">
                            <Add
                              onClick={() => handleClickAdd(item)}
                              className={classes.success}
                            />
                          </IconButton>
                        )
                      }
                    />
                  ))}
              </>
            )}
          </List>
        </Grid>
      </Grid>
    </>
  )
}
