import { Fragment, useContext } from 'react'
import { Box, makeStyles, TextField } from '@material-ui/core'
import { PodcastContext, PodcastContextType } from '../context/PodcastContext'
import { styles } from './styles'
import { useGlobal } from '@Hooks/useGlobal'
import SettingEditor from '../Widget/editor2'

const useStyles = makeStyles(styles)

export default function Main() {
  const {
    handleChange,
    podcast: [podcast],
  } = useContext(PodcastContext) as PodcastContextType
  const { wording,theme_name } = useGlobal()
  const classes = useStyles()

  return (
    <Fragment>
      <Box className={classes.mainContainer}>
        <div className={classes.mainItem}>
          <input
            id="outlined-textarea"
            placeholder="หัวข้อ"
            value={podcast?.title || ''}
            style={{
              fontSize: '30px',
              border: '0',
              outline: 'none',
              width: '100%',
            }}
            onChange={(ev) => {
              handleChange('title', ev.target.value)
            }}
          />
        </div>
        { theme_name !== 'mirror'  && (
          <div className={classes.mainItem}>
           {podcast?.id && <SettingEditor  />}
          </div>
        )}
        {/* <div className={classes.mainItem}>
          <TextField
            id="outlined-textarea"
            multiline
            rowsMax={5}
            placeholder={wording.content_page.summary}
            value={podcast?.summary || ''}
            fullWidth
            onChange={(ev) => {
              handleChange('summary', ev.target.value)
            }}
          />
        </div> */}
      </Box>
    </Fragment>
  )
}
