import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { ReactElement, useState, useEffect, Fragment } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import {
  QUERY_GET_ALL_CATEGORIES,
  QUERY_GET_CATEGORY,
  MUTATION_ADD_CATEGORY,
  MUTATION_EDIT_CATEGORY,
} from '@Api/category_api'

import {ICategory } from '@Types/category_type'
import Loading from '@Components/Loading'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import { Button, Divider, FormControl, Grid, InputLabel, Paper, Select, TextField, Typography } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      minHeight: 650,
      padding: theme.spacing(2),
    },
    spaceRow: {
      marginTop: theme.spacing(2),
    },
    grow: {
      flexGrow: 1,
    },
    divider: {
      marginBlock: theme.spacing(1),
    },
    formControl: {
      minWidth: '100%',
    },
  }),
)
interface ParamType {
  id: string
}
export default function EditCategory(): ReactElement {
  const { checkPermission } = useAuth() as AuthContextType

  useEffect(() => {
    const checked = checkPermission('category_edit')
    if (!checked) {
      window.location.href = '/'
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams<ParamType>()
  const [addCategoryApi] = useMutation(MUTATION_ADD_CATEGORY)
  const [editCategoryApi] = useMutation(MUTATION_EDIT_CATEGORY)

  const defaultCategory: ICategory = {
    _id: "",
    label: "",
    value: "",
    status: 10,
  }

  const { data: dataAll } = useQuery(QUERY_GET_ALL_CATEGORIES)

  const [category, setCategory] = useState<ICategory>()
  const [fetch, { loading, error }] = useLazyQuery(QUERY_GET_CATEGORY, {
    variables: {
      params: {
        _id: id,
      }
    },
    onCompleted: async (data) => {
      if (data?.Category) {
        setCategory(data.Category)
      }
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    !id ? setCategory(defaultCategory) : fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const addCategory = async (obj: any) => {
    const res = await addCategoryApi({
      variables: {
        ...obj,
      },
      refetchQueries: [
        {
          query: QUERY_GET_ALL_CATEGORIES,
        },
      ],
    })
    if (res.data.AddCategory.statusCode === 200) {
      history.push('/category')
    }
  }

  const editCategory = async (obj:any) => {
    const res = await editCategoryApi({
      variables: {
        ...obj,
        _id: id,
      },
      refetchQueries: [
        {
          query: QUERY_GET_ALL_CATEGORIES,
        },
      ],
    })
    if (res.data.EditCategory.statusCode === 200) {
      history.push('/category')
    }
  }

  const handleSubmit =  async (e: React.SyntheticEvent) => {
    e.preventDefault()

    const target = e.target as typeof e.target & {
      label: { value: string }
      value: { value: string }
      status: { value: string }
    }

    const obj = {
      label:  target.label.value,
      value: target.value.value,
      status:  parseInt(target.status.value),
    }

    // validate duplicate & null
    let filterI = dataAll.Categories.filter((v:any)=> v._id !== id)
    let labelAll = filterI.map((v:any) => v.label.toLowerCase())
    let valueAll = filterI.map((v:any) => v.value.toLowerCase())
    if( !obj.label || !obj.value ){
      alert("label or value null")
    }else if( labelAll.indexOf(obj.label.toLowerCase()) !== -1 ||  valueAll.indexOf(obj.value.toLowerCase()) !== -1  ){
      alert("label or value duplicate")
    }else {
      if( !id ){
        addCategory(obj)
      }else{
        editCategory(obj)
      }
    }
  }

  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }

  return (
    <Fragment>
      {!!category && (
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item>
                <Typography variant="h6">{category?._id ? "แก้ไข" : "เพิ่ม"}ข้อมูลประเภท</Typography>
              </Grid>
              <div className={classes.grow} />
              <Grid item>
                {checkPermission('category_edit') && (
                  <Button
                    startIcon={<SaveIcon />}
                    variant="outlined"
                    type="submit"
                  >
                    บันทึกข้อมูล
                  </Button>
                )}
              </Grid>
            </Grid>
            <Divider className={classes.divider} />

            <Grid container justify="center">
              <Grid item xs={6} className={classes.spaceRow}>
                <TextField
                    name="label"
                    label="Label"
                    size="small"
                    // variant="outlined"
                    fullWidth
                    defaultValue={category?.label}
                  />
              </Grid>
            </Grid>
            <Grid container className={classes.spaceRow} justify="center">
              <Grid item xs={6}>
                <TextField
                    name="value"
                    label="Value"
                    size="small"
                    // variant="outlined"
                    fullWidth
                    defaultValue={category?.value}
                  />
              </Grid>
            </Grid>
            <Grid container className={classes.spaceRow} justify="center">
              <Grid item xs={6}>
                <FormControl
                    // variant="outlined"
                  className={classes.formControl}
                    size="small"
                  >
                    <InputLabel>สถานะ</InputLabel>
                    <Select
                      // value={age}
                      // onChange={handleChange}
                      defaultValue={category?.status || 0}
                      label="สถานะ"
                      name="status"
                      native
                    >
                      <option value={10}>ใช้งาน</option>
                      <option value={0}>ไม่ได้ใช้งาน</option>
                    </Select>
                  </FormControl>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </Fragment>
  )
}
