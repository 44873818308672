import { gql } from '@apollo/client'

export const QUERY_GET_ALL_PODCAST = gql`
  query CmsPodcastsDraft(
    $podcastParams: PodcastParams
    $page: Int
    $limit: Int
  ) {
    CmsPodcastsDraft(params: $podcastParams, page: $page, limit: $limit) {
      total
      data {
        id
        title
        widgets {
          layout
          data {
            title
            abstract
            description
            thumbnails
          }
        }
        podbean_url
        podbean_id
        images
        status
        author {
          _id
          first_name
          last_name
        }
        premium
        topic {
          _id
          value
          label
        }
        categories {
          _id
          value
          label
        }
        channel {
          _id
          value
          label
        }
        tags
        publish_time
        created_time
        expire_time
        summary
        abstract
        created_by {
          first_name
          last_name
          nick_name
        }
        updated_time
        share
        cover
        duration
      }
    }
  }
`

export const QUERY_GET_PODCAST_BY_ID = gql`
  query CmsPodcastDraft($id: String) {
    CmsPodcastDraft(id: $id) {
      id
      title
      widgets {
        layout
        data {
          title
          abstract
          description
          thumbnails
        }
      }
      podbean_url
      podbean_id
      images
      status
      author {
        _id
        first_name
        last_name
      }
      premium
      topic {
        _id
        value
        label
      }
      categories {
        _id
        value
        label
      }
      channel {
          _id
          value
          label
          slug
        }
      tags
      publish_time
      created_time
      expire_time
      summary
      abstract
      token
      share
      cover
      duration
    }
  }
`

export const QUERY_GET_TOPIC_AND_CATEGORY_AND_CHANNEL = gql`
  query TopicsAndCategories($categoryParams: CategoryParams) {
    Topics {
      id: _id
      value: label
      topic_value: value
    }
    Categories(params: $categoryParams, order_by: { field: label, sort: asc }) {
      id: _id
      value: label
    }
    Channels{
      id: _id
      value: title
    }
  }
`

export const QUERY_GET_OPTION_SEARCH = gql`
  query {
    Topics {
      id: _id
      value: label
    }
    Categories {
      id: _id
      value: label
    }
    Authors: CmsAuthors {
      first_name
      last_name
      value: full_name
      id: _id
    }
  }
`

export const MUTATION_ADD_PODCAST = gql`
  mutation AddPodcastDraft(
    $title: String
    $widgets: [WidgetInput]
    $podbean_id: String
    $podbean_url: String
    $images: [String]
    $status: String
    $author: AuthorInput
    $premium: String
    $topic: [String]
    $categories: [String]
    $tags: [String]
    $publish_time: Date
    $expire_time: Date
    $summary: String
    $abstract: String
    $token: String
    $share: String
    $cover: String
    $duration: String
    $channel: [String]
  ) {
    AddPodcastDraft(
      title: $title
      widgets: $widgets
      podbean_id: $podbean_id
      podbean_url: $podbean_url
      images: $images
      status: $status
      author: $author
      premium: $premium
      topic: $topic
      categories: $categories
      tags: $tags
      publish_time: $publish_time
      expire_time: $expire_time
      summary: $summary
      abstract: $abstract
      token: $token
      share: $share
      cover: $cover
      duration: $duration
      channel: $channel
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_PODCAST = gql`
  mutation EditPodcastDraft(
    $id: String!
    $title: String
    $widgets: [WidgetInput]
    $podbean_id: String
    $podbean_url: String
    $images: [String]
    $status: String
    $author: AuthorInput
    $premium: String
    $topic: [String]
    $categories: [String]
    $tags: [String]
    $publish_time: Date
    $expire_time: Date
    $summary: String
    $abstract: String
    $token: String
    $share: String
    $cover: String
    $duration: String
    $channel: [String]
  ) {
    EditPodcastDraft(
      id: $id
      title: $title
      widgets: $widgets
      podbean_id: $podbean_id
      podbean_url: $podbean_url
      images: $images
      status: $status
      author: $author
      premium: $premium
      topic: $topic
      categories: $categories
      tags: $tags
      publish_time: $publish_time
      expire_time: $expire_time
      summary: $summary
      abstract: $abstract
      token: $token
      share: $share
      cover: $cover
      duration: $duration
      channel: $channel
    ) {
      statusCode
      message
      data
    }
  }
`
export const QUERY_GET_PODCAST_HISTORY = gql`
  query CmsPodcastHistory($id: String) {
    CmsPodcastHistory(id: $id) {
      created_by {
        first_name
        last_name
        profile_image
        description
      }
      publish_time
    }
  }
`

export const MUTATION_PUBLISH_PODCAST = gql`
  mutation PublishPodcast(
    $id: String!
    $publish_time: Date
    $expire_time: Date
  ) {
    PublishPodcast(
      id: $id
      publish_time: $publish_time
      expire_time: $expire_time
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_PODCAST_STATUS = gql`
  mutation ($id: String!, $status: String!) {
    EditPodcastStatus(id: $id, status: $status) {
      statusCode
      message
      data
    }
  }
`
