import { SvgIcon, SvgIconProps } from '@material-ui/core'
import React, { ReactElement } from 'react'
interface Props extends SvgIconProps {}
export default function MirrorIcon(props: Props): ReactElement {
  return (
    <>
      <SvgIcon {...props}>
        <svg viewBox="0 0 87.715 17.255">
          <g transform="translate(-7.8 -9)">
            <g data-name="Group 692" transform="translate(7.8 9)">
              <path
                data-name="Path 2369"
                d="M7.8,25.824V9h2.3l7.477,14.811.288,1.007h.288l.288-1.007L25.918,9h2.3V25.824H26.781V10.007h-.288l-.431,1.582L18.728,25.824H17L9.669,11.588l-.288-1.582H9.094V25.824Z"
                transform="translate(-7.8 -9)"
                fill="#d9c19a"
              ></path>
              <path
                data-name="Path 2370"
                d="M25.6,25.824V9h1.438V25.824Z"
                transform="translate(-0.004 -9)"
                fill="#d9c19a"
              ></path>
              <path
                data-name="Path 2371"
                d="M36.427,18.2l.575.288,6.471,7.19H41.46L34.7,17.915H31.538V25.68H30.1V9h7.765c2.445,0,4.026.719,4.889,2.157a4.22,4.22,0,0,1,.575,2.3,3.92,3.92,0,0,1-.575,2.3c-.863,1.438-2.588,2.157-5.033,2.157H36.283V18.2Zm-4.745-7.909v6.471h6.327c1.869,0,3.164-.575,3.739-1.582a3.667,3.667,0,0,0,0-3.451c-.575-1.007-1.726-1.582-3.739-1.582H31.682Z"
                transform="translate(1.966 -9)"
                fill="#d9c19a"
              ></path>
              <path
                data-name="Path 2372"
                d="M53.454,9.288l-1.007,2.876H52.16a1.912,1.912,0,0,0-1.15-.431c-1.726,0-2.3.719-7.046,10.066L42.957,25.68H40.8l3.595-13.8c.288-1.294-.144-1.726-1.726-1.582l-.719.144V10.15L47.271,9l-3.02,11.36C48.709,10.726,50.434,9,52.3,9A4.445,4.445,0,0,1,53.454,9.288Z"
                transform="translate(6.653 -9)"
                fill="#fff"
              ></path>
              <path
                data-name="Path 2373"
                d="M48.7,21.366C48.7,16.765,52.582,9,58.478,9c2.3,0,4.745,1.15,4.745,4.889,0,4.6-3.882,12.366-9.778,12.366C51.145,26.255,48.7,25.105,48.7,21.366ZM60.635,13.6c0-3.307-1.15-4.026-2.445-4.026-4.026,0-7.046,6.758-7.046,12.223,0,3.307,1.15,4.026,2.445,4.026C57.615,25.824,60.635,19.066,60.635,13.6Z"
                transform="translate(10.112 -9)"
                fill="#fff"
              ></path>
              <path
                data-name="Path 2374"
                d="M72.654,9.288l-1.007,2.876H71.36a1.912,1.912,0,0,0-1.15-.431c-1.726,0-2.3.719-7.046,10.066L62.157,25.68H60l3.595-13.8c.288-1.294-.144-1.726-1.726-1.582l-.719.144V10.15L66.471,9l-3.02,11.36C68.053,10.726,69.778,9,71.5,9A4.445,4.445,0,0,1,72.654,9.288Z"
                transform="translate(15.061 -9)"
                fill="#fff"
              ></path>
            </g>
          </g>
        </svg>
      </SvgIcon>
    </>
  )
}
