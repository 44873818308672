import { InputBase } from '@material-ui/core'
import { Fragment, useContext } from 'react'
import { ContentContext, ContentContextType } from '../context/ContentContext'
// import { Input } from './component/Input/index'
import SettingEditor from './editor2'
import { widgetRules, rulesIf } from './rules'

export default function StoryTelling(props: any) {
  const {
    handleChange,
    content: [content],
  } = useContext(ContentContext) as ContentContextType
 
  return (
    <Fragment>
      {content &&
        content.widgets.filter(opt => opt.layout==="default").map((item: any, index: number) => {
          const rules: rulesIf = widgetRules[item.template || 'basic']
          return (
            <Fragment key={`widget_${index}`}>
              {rules.title && (
                <InputBase
                  // label="Title"
                  placeholder="input title ..."
                  value={item.title}
                  onChange={(ev) => {
                    handleChange(`widgets[${index}].title`, ev.target.value)
                  }}
                />
              )}
              {rules.abstract && (
                <InputBase
                  // label="Abstract"
                  placeholder="input abstract ..."
                  value={item.abstract}
                  onChange={(ev) => {
                    handleChange(`widgets[${index}].abstract`, ev.target.value)
                  }}
                />
              )}
              {content.id && (
                <>
                  <SettingEditor index={index} />
                </>
              )}
            </Fragment>
          )
        })}
    </Fragment>
  )
}
