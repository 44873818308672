import {
  MUTATION_ADD_SUGGESTION_TAG,
  QUERY_GET_SUGGESTION_TAG_BY_SECTION,
} from '@Api/suggestion_tags_api'
import { useMutation, useQuery } from '@apollo/client'
import { useAuth } from '@Hooks/useAuth'
import { useSnackbar } from '@Hooks/useSnackbar'
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import { Add, Delete, Save } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { IDataSuggestionTag, ISuggestionTag } from '@Types/suggestion_tag_type'
import { ITopic } from '@Types/topic_type'
import React, { ReactElement, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
interface Props {
  topic: ITopic
}
const useStyles = makeStyles((theme: Theme) => ({
  card: {
    '& > *': {
      padding: theme.spacing(1),
    },
  },
  description_container: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  textfield: {
    width: '100%',
  },
  textfield_tag: {
    width: '30%',
  },
  textfield_tag_wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  body: {
    padding: theme.spacing(1),
    width: '100%',
  },
  button_add: {},
  button_delete: {},
  tags: {
    marginTop: theme.spacing(2),
  },
  add_card: {
    minHeight: 46,
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    borderStyle: 'dashed',
  },
  button_save: {
    marginBottom: theme.spacing(2),
  },
  icon_save: {
    color: theme.palette.success.main,
  },
}))

const initial_data = {
  data: [],
  section: '',
  ts: new Date(),
}

function Detail({ topic }: Props): ReactElement {
  const classes = useStyles()

  const [suggestionTags, setSuggestionTags] =
    useState<ISuggestionTag>(initial_data)
  const [open, setOpen] = useState(false)
  const [deleteData, setDeleteData] = useState<IDataSuggestionTag>()
  const { checkPermission } = useAuth()
  const { onToggle } = useSnackbar()
  useQuery(QUERY_GET_SUGGESTION_TAG_BY_SECTION, {
    variables: {
      section: topic.value,
    },
    onCompleted: ({ CmsSuggestionTag }) =>
      setSuggestionTags(CmsSuggestionTag || initial_data),
    fetchPolicy: 'no-cache',
  })

  const [saveSuggestion] = useMutation(MUTATION_ADD_SUGGESTION_TAG)

  const handleChangeInput = (description: string, id: string) => {
    setSuggestionTags((prev) => ({
      ...prev,
      data: prev.data.map((item) => {
        return item._id === id ? { ...item, description } : item
      }),
    }))
  }
  const handleAddTags = (tags: string[], id: string) => {
    setSuggestionTags((prev) => ({
      ...prev,
      data: prev.data.map((item) => {
        return item._id === id ? { ...item, tags } : item
      }),
    }))
  }

  const handleAddCardTag = () => {
    setSuggestionTags((prev) => ({
      ...suggestionTags,
      data: [
        ...prev.data,
        {
          _id: uuidv4(),
          description: '',
          tags: [],
        },
      ],
    }))
  }

  const handleDeleteCardTag = () => {
    if (deleteData) {
      setSuggestionTags((prev) => ({
        ...suggestionTags,
        data: [...prev.data.filter((f) => f._id !== deleteData._id)],
      }))
      setDeleteData(undefined)
      setOpen(false)
    }
  }

  const handleClickSave = async () => {
    if (!checkPermission('seo_tag_suggestion_edit')) return
    // console.log(`LOG: data ---> `, {
    //   section: topic.value,
    //   data: suggestionTags.data,
    // })
    const {
      data: { AddSuggestionTag },
    } = await saveSuggestion({
      variables: {
        section: topic.value,
        data: suggestionTags.data,
      },
    })

    if (AddSuggestionTag?.statusCode === 200) {
      onToggle({
        type: 'success',
        open: true,
        message: 'บันทึกข้อมูลสำเร็จ!',
      })
    } else {
      onToggle({
        type: 'error',
        open: true,
        message: 'ไม่สามารถบันทึกข้อมูลได้!',
      })
    }
  }
  useEffect(() => {
    if (!checkPermission('seo_tag_suggestion_view')) {
      window.location.href = '/'
    }
    return () => {}
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  //------------------------------- permission
  return (
    <div>
      <Button
        className={classes.button_save}
        startIcon={<Save className={classes.icon_save} />}
        variant="outlined"
        onClick={handleClickSave}
        disabled={!checkPermission('seo_tag_suggestion_edit')}
      >
        บันทึก
      </Button>
      <Grid container spacing={2}>
        {suggestionTags &&
          suggestionTags.data.map((item, index) => (
            <Grid item xs={12} key={item._id}>
              <Card className={classes.card} variant="outlined">
                <CardContent className={classes.description_container}>
                  <div className={classes.body}>
                    <TextField
                      label="รายละเอียด"
                      variant="outlined"
                      size="small"
                      className={classes.textfield}
                      defaultValue={item.description}
                      onChange={(e) =>
                        handleChangeInput(e.target.value, item._id)
                      }
                    />
                    <Autocomplete
                      className={classes.tags}
                      multiple
                      freeSolo
                      options={[]}
                      getOptionLabel={(option) => {
                        return `${option}` // fix typescript
                      }}
                      value={item.tags}
                      onChange={(event, new_value) => {
                        handleAddTags(new_value as string[], item._id)
                      }}
                      filterSelectedOptions
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Tags"
                            placeholder="พิมพ์..."
                            fullWidth
                            size="small"
                          />
                        )
                      }}
                    />
                  </div>
                  <div className={classes.grow} />
                  <IconButton
                    onClick={() => {
                      setDeleteData(item)
                      setOpen(true)
                    }}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          ))}
        <Grid item xs={12}>
          <Button
            fullWidth
            className={classes.add_card}
            startIcon={<Add />}
            variant="outlined"
            onClick={handleAddCardTag}
          >
            เพิ่มชุด Tag
          </Button>
        </Grid>
      </Grid>

      <Dialog maxWidth="sm" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>ต้องการลบชุด Tag นี้ ?</DialogTitle>
        <DialogContent>
          <DialogContentText>{deleteData?.description}</DialogContentText>
          <DialogContentText>{deleteData?.tags.join(', ')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCardTag}
            autoFocus
            startIcon={<Delete color="error" />}
            color="secondary"
            // variant="outlined"
          >
            ยืนยัน
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

// const mockData: ISuggestionTag = {
//   section: 'speak',
//   data: [
//     {
//       _id: uuid(),
//       description: 'แนะนำ 1',
//       tags: ['tag1', 'tag2', 'tag3'],
//     },
//     {
//       _id: uuid(),
//       description: 'แนะนำ 2',
//       tags: [
//         'tag12',
//         'tag22',
//         'tag32',
//         'tag12',
//         'tag22',
//         'tag32',
//         'tag12',
//         'tag22',
//         'tag32',
//         'tag12',
//         'tag22',
//         'tag32',
//         'tag12',
//         'tag22',
//         'tag32',
//         'tag12',
//         'tag22',
//         'tag32',
//         'tag12',
//         'tag22',
//         'tag32',
//       ],
//     },
//   ],
//   ts: new Date(),
// }

export default Detail
