import React, { Fragment, useState } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
import Detail from './detail'
import { useQuery } from '@apollo/client'
import { QUERY_GET_ALL_TOPICS } from '@Api/topic_api'
import { QUERY_GET_SUBTOPICS } from '@Api/topic_sub_api'
import { ITopic } from '@Types/topic_type'
import { ISubTopic } from '@Types/topic_sub_type'
import { useGlobal } from '@Hooks/useGlobal'
import { v4 as uuidv4 } from 'uuid';

interface StyledTabProps {
  label?: string
  disabled?: boolean
  chlidren?: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  root: { flexGrow: 1, },
  demo1: { backgroundColor: theme.palette.background.paper, },
  padding: { padding: theme.spacing(3), },
  grow: { flexGrow: 1, },
  header_container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  status_wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: { marginInline: theme.spacing(1), },
  saveBtn: {
    background: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      background: '#439c46',
    },
    margin: theme.spacing(1),
  },
}))

const SubLatestPick = () => {
  const classes = useStyles()
  const [tabNow, setTabNow] = useState(0)
  const [tabSubTopic, setTabSubTopic] = useState(0)
  const { theme_name } = useGlobal()
  const [topics, setTopics] = useState<ITopic[]>([])
  const [selectTopic, setSelectTopic] = useState<ITopic>()
  const [subTopics, setSubTopics] = useState<ISubTopic[]>([])
  const [numFilterSubTopic, setNumFilterSubTopic] = useState(0)
  const [selectSubTopics, setSelectSubTopics] = useState<ISubTopic>()

  const topicOthers: ITopic[] =
    theme_name === 'thairath-plus'
      ? [
          {
            _id: uuidv4(),
            value: 'home',
            label: 'Home',
          },
        ]
      : []

  useQuery(QUERY_GET_ALL_TOPICS, {
    onCompleted: ({ Topics }) => {
      if (Topics)
        setTopics(
          [...topicOthers, ...Topics].filter(
            (item: ITopic) => item.value !== 'article',
          ),
        )
      else setTopics(topicOthers)
    },
  })

  useQuery(QUERY_GET_SUBTOPICS, {
    onCompleted: ({ SubTopics }) => {
      setSubTopics(SubTopics)
    },
  })

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNow(newValue)
    setSelectTopic(topics[newValue])
    setTabSubTopic(-1)

    let countFilterSubTopic = subTopics.filter(item => item.topicRef===topics[newValue]._id).length;
    setNumFilterSubTopic(countFilterSubTopic)
  }

  const handleChangeSubTopic = (event: React.ChangeEvent<{}>, newValue: number) => {
    let tmpSubTopic = subTopics.filter((item) => item.topicRef === selectTopic?._id)
    setTabSubTopic(newValue)
    setSelectSubTopics(tmpSubTopic[newValue])
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.demo1}>
          <AntTabs onChange={handleChange} value={tabNow}>
            {topics &&
              topics.map((topic: ITopic) => (
                <AntTab key={topic._id} label={topic.label} />
              ))}
          </AntTabs>
          {
            (numFilterSubTopic > 0) && 
              <AntTabs onChange={handleChangeSubTopic} value={tabSubTopic}>
                {subTopics &&
                  subTopics.map((subtopic: ISubTopic) => (
                    (selectTopic && subtopic.topicRef === selectTopic._id) && <AntTab key={subtopic._id} label={subtopic.label} />
                  ))}
              </AntTabs>
          }
          {
            (numFilterSubTopic > 0 && selectSubTopics) && 
              <div className={classes.padding}>
                {topics[tabNow] && subTopics[tabSubTopic] && <Detail topic={topics[tabNow]} subtopic={selectSubTopics} />}
              </div>
          }
        </div>
      </div>
    </Fragment>
  )
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    position: 'relative',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      marginRight: theme.spacing(3),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => (
  <Tab disableRipple {...props}>
    {props.chlidren}
  </Tab>
))

export default SubLatestPick
