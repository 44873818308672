import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import { QUERY_GET_ALL_USERS } from '@Api/user_api'
import ButtonLink from '@Components/ButtonLink'
import LinkRouter from '@Components/LinkRouter'
import AddIcon from '@material-ui/icons/Add'
// import EditIcon from '@material-ui/icons/Edit'
// import IconButtonLink from '@Components/IconButtonLink'
import { useQuery } from '@apollo/client'
import Loading from '@Components/Loading'
// import { Link } from 'react-router-dom'
// import { checkPermission } from "@Components/checkPermission"
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import { useEffect } from 'react'

const columns: Array<MUIDataTableColumnDef> = [
  {
    name: '_id',
    label: '#',
    options: {
      filter: true,
      sort: true,
      customBodyRenderLite: (dataIndex) => <>{dataIndex + 1}</>,
    },
  },
  {
    name: 'first_name',
    label: 'First Name',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <LinkRouter to={`/user/edit/${rowData[0]}`}>{value}</LinkRouter>
      ),
    },
  },
  {
    name: 'last_name',
    label: 'Last Name',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <LinkRouter to={`/user/edit/${rowData[0]}`}>{value}</LinkRouter>
      ),
    },
  },
  {
    name: 'username',
    label: 'Username',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <LinkRouter to={`/user/edit/${rowData[0]}`}>{value}</LinkRouter>
      ),
    },
  },
  {
    name: 'nick_name',
    label: 'Nick Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'is_active',
    label: 'สถานะผู้ใช้งาน',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => (
        <>{value === '1' ? '	ใช้งาน' : 'ระงับการใช้งาน'}</>
      ),
    },
  },
  {
    name: 'last_update',
    label: 'Last Update',
    options: {
      filter: true,
      sort: true,
    },
  },
  // {
  //   name: '_id',
  //   label: 'แก้ไขข้อมูล',
  //   options: {
  //     filter: true,
  //     sort: true,
  //     customBodyRender: (value) => (
  //       <IconButtonLink to={`/user/edit/${value}`} size="small">
  //         <EditIcon color="primary" />
  //       </IconButtonLink>
  //     ),
  //   },
  // },
]
export default function User() {

  const { checkPermission } = useAuth() as AuthContextType

  useEffect(() =>{
    const checked = checkPermission("admin_view")
    if(!checked){
      window.location.href = '/'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const { loading, error, data } = useQuery(QUERY_GET_ALL_USERS)
  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <>
      <MUIDataTable
        title={'ผู้ใช้งานทั้งหมด'}
        columns={columns}
        data={data.Users}
        options={{
          print: false,
          download: false,
          selectableRows: 'none',
          customToolbar: ({ displayData }) => {
            return (
              <>
                <ButtonLink
                  to="/user/create"
                  startIcon={<AddIcon />}
                  style={{ order: -1 }}
                  variant="outlined"
                >
                  เพิ่มผู้ใช้งาน
                </ButtonLink>
              </>
            )
          },
          setTableProps: () => {
            return {
              size: 'small',
            }
          },
        }}
      />
    </>
  )
}
