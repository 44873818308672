import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
} from '@material-ui/core'
import React, { ReactElement, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Add, Close, Save } from '@material-ui/icons'
import { ITopic } from '@Types/topic_type'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import { useSnackbar } from '@Hooks/useSnackbar'
import { QUERY_GET_ALL_MIRROR_PRODUCTS_FOR_PICK } from '@Api/mirror/product_api'
import { IMirrorProduct } from '@Types/mirror/product_type'
import DragAndDrop, { DragItem, DropItem } from '@Components/DragAndDrop'
import CardProduct from '@Components/CardProduct'
import {
  MUTATION_ADD_PRODUCT_MANAGE,
  QUERY_GET_PRODUCT_MANAGE_BY_SECTION,
} from '@Api/mirror/product_manage_api'
import Loading from '@Components/Loading'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    grow: {
      flexGrow: 1,
    },
    picked: {
      marginLeft: theme.spacing(2),
    },
    divider: {
      marginInline: theme.spacing(2),
    },
    success: {
      color: theme.palette.success.main,
    },
    button_save: {
      marginRight: theme.spacing(2),
    },
    product_card: {
      marginBottom: theme.spacing(1),
    },
  }),
)
interface DetailProps {
  topic: ITopic
}

export default function Detail({ topic }: DetailProps): ReactElement {
  const { checkPermission } = useAuth() as AuthContextType
  const [saveAddBtn, setStateSaveAddBtn] = useState<boolean>(false)
  const [products, setProducts] = useState([])
  const [limit] = useState(100)
  const { onToggle } = useSnackbar()
  const classes = useStyles()
  const [selected, setSelected] = useState<IMirrorProduct[]>([])
  const [isRandom, setIsRandom] = useState(false)

  useQuery(QUERY_GET_ALL_MIRROR_PRODUCTS_FOR_PICK, {
    variables: {
      page: 1,
      limit,
      params: {
        status: '10',
      },
    },
    onCompleted: (data) => {
      if (!data?.CmsMirrorProducts) return
      setProducts(data.CmsMirrorProducts)
    },
  })

  const { loading } = useQuery(QUERY_GET_PRODUCT_MANAGE_BY_SECTION, {
    variables: {
      section: topic.value,
    },
    onCompleted: (data) => {
      if (!data?.CmsMirrorProductManage) return setSelected([])
      setIsRandom(data.CmsMirrorProductManage.is_random)
      setSelected(data.CmsMirrorProductManage.products)
    },
  })

  useEffect(() => {
    const checked = checkPermission('mirror_product_view')
    const checkedEdit = checkPermission('mirror_product_edit')
    if (!checked) {
      window.location.href = '/'
    }

    if (checkedEdit) {
      setStateSaveAddBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [save] = useMutation(MUTATION_ADD_PRODUCT_MANAGE, {
    refetchQueries: [
      {
        query: QUERY_GET_PRODUCT_MANAGE_BY_SECTION,
        variables: {
          section: topic.value,
        },
      },
    ],
  })
  const handleClickSave = async () => {
    const res = await save({
      variables: {
        section: topic.value,
        products: selected.map((item) => item._id),
        is_random: isRandom,
      },
    })
    if (res.data?.AddMirrorProductManage?.statusCode === 200) {
      onToggle({ type: 'success', open: true, message: 'บันทึกข้อมูลสำเร็จ!' })
    } else {
      onToggle({
        type: 'error',
        open: true,
        message: 'ไม่สามารถบันทึกข้อมูลได้!',
      })
      console.log(`LOG: cannot save latest pick ---> `, res.data.message)
    }
    // setFormData(new_form_data)
    // console.log(`LOG: new_form_data ---> `, new_form_data)
  }
  const handleClickAdd = (product: IMirrorProduct) => {
    if (selected.findIndex((f) => f._id === product._id) !== -1) {
      onToggle({
        type: 'warning',
        open: true,
        message: 'คุณได้เลือกสินค้านี้ไปแล้ว!',
      })
      return
    }
    setSelected([...selected, product])
  }

  const handleClickRemove = (product: IMirrorProduct) => {
    const new_arr = selected.filter((item) => item._id !== product._id)
    setSelected([...new_arr])
  }

  // if (error) return <>error :(</>
  return (
    <Grid container spacing={8} alignItems="flex-start">
      <Grid item xs={12} lg={4}>
        <FormControlLabel
          control={
            <Switch
              name="random"
              checked={isRandom}
              onChange={(ev,checked)=>{setIsRandom(checked)}}
              color="primary"
            />
          }
          label="สุ่ม"
        />
        <Grid container>
          <Grid item>
            <Typography className={classes.picked} variant="h6">
              เลือกแล้ว
            </Typography>
          </Grid>
          <div className={classes.grow} />
          {saveAddBtn ? (
            <Grid item>
              <Button
                onClick={handleClickSave}
                className={classes.button_save}
                size="small"
                startIcon={<Save className={classes.success} />}
              >
                บันทึก
              </Button>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <Divider variant="fullWidth" className={classes.divider} />
        {loading ? (
          <Loading />
        ) : (
          <DragAndDrop
            data={selected}
            onChange={(new_data) => {
              setSelected(new_data)
            }}
          >
            <List className={classes.root} dense>
              <DropItem droppableId="droppable">
                {selected.map((item, index) => (
                  <DragItem
                    index={index}
                    draggableId={item._id || `draggableId_${index}`}
                    key={item._id}
                    // key must have !!!!
                    // no key = bug
                  >
                    <CardProduct
                      product={item}
                      action={() =>
                        saveAddBtn && (
                          <>
                            <Link
                              component="a"
                              href={item.product_url}
                              target="_blank"
                            >
                              <OpenInNewIcon />
                            </Link>
                            <IconButton
                              onClick={() => handleClickRemove(item)}
                              size="small"
                            >
                              <Close color="error" />
                            </IconButton>
                          </>
                        )
                      }
                      className={classes.product_card}
                    />
                  </DragItem>
                ))}
              </DropItem>
            </List>
          </DragAndDrop>
        )}
      </Grid>
      {/* <Grid item xs={1}></Grid> */}
      <Grid container item spacing={1} xs={12} lg={8}>
        {products &&
          products.map((item: IMirrorProduct) => (
            <Grid item key={item._id} xs={12} md={6} xl={4}>
              <CardProduct
                product={item}
                action={() =>
                  saveAddBtn && (
                    <>
                      <Link
                        component="a"
                        href={item.product_url}
                        target="_blank"
                      >
                        <OpenInNewIcon />
                      </Link>
                      <IconButton
                        onClick={() => handleClickAdd(item)}
                        size="small"
                      >
                        <Add className={classes.success} />
                      </IconButton>
                    </>
                  )
                }
              />
            </Grid>
          ))}
      </Grid>
    </Grid>
  )
}
