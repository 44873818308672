import { PodcastContextProvider } from './context/PodcastContext'
import PodcastTab from './tab'

const EditPodcast = () => {
  return (
    <PodcastContextProvider>
      <PodcastTab />
    </PodcastContextProvider>
  )
}

export default EditPodcast
