import { ReactElement, Fragment } from 'react'
import { 
  //useHistory, 
  useParams 
} from 'react-router-dom'
import { useAuth } from '@Hooks/useAuth'
import useSeo from '../context/SeoContext'
import { useSnackbar } from '@Hooks/useSnackbar'
import {
  TextField,
  Grid,
  makeStyles,
  Paper,
  Button,
  Typography,
  Divider,
} from '@material-ui/core'
import { Save } from '@material-ui/icons'
import { useMutation } from '@apollo/client'
//import Autocomplete from '@material-ui/lab/Autocomplete'
import { MUTATION_EDIT_METATAG, QUERY_ALL_METATAGS } from '@Api/metatag_api'

//import { QUERY_GET_ALL_METATAG_PATH } from '@Api/metatag_path_api'
const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minHeight: 650,
    padding: theme.spacing(2),
  },
  spaceRow: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  divider: {
    marginBlock: theme.spacing(1),
  },
}))
export default function Detail(): ReactElement {
  const { checkPermission } = useAuth() 
  const { onToggle } = useSnackbar() 
  //const history = useHistory()
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const { data,setData } = useSeo() as any
 
  const [editMetatag] = useMutation(MUTATION_EDIT_METATAG)
  const onSubmit = async () => {
    const res = await editMetatag({
      variables: {
        ...data,
        _id: id,
      },
      refetchQueries: [
        {
          query: QUERY_ALL_METATAGS,
        },
      ],
    })
    if (res.data?.EditMetatag?.statusCode === 200) {
      onToggle({ type: 'success', open: true, message: 'บันทึกข้อมูลสำเร็จ!' })
    } else {
      onToggle({
        type: 'error',
        open: true,
        message: 'ไม่สามารถบันทึกข้อมูลได้!',
      })
      console.log(`LOG: cannot save latest pick ---> `, res.data.message)
    }
  }

  return (
    <Fragment>
      {!!data && (
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item>
              <Typography variant="h6">MetaTag</Typography>
            </Grid>
            <div className={classes.grow} />
            <Grid item>
              {checkPermission('seo_tag_pages_edit') && (
                <Button
                  startIcon={<Save />}
                  onClick={onSubmit}
                  variant="outlined"
                  type="submit"
                >
                  บันทึกข้อมูล
                </Button>
              )}
            </Grid>
          </Grid>
          <Divider className={classes.divider} />

          <Grid container justify="center">
            <Grid item xs={6}>
              <TextField
                id="meta-path"
                label="Path"
                defaultValue={data.path}
                fullWidth
                disabled
              />
            </Grid>
            {/* <Grid item xs={6} className={classes.spaceRow}> */}

            {/* <Autocomplete
                disabled
                loading={loading_path}
                id="path"
                fullWidth
                options={data_path?.MetatagPath && data_path.MetatagPath}
                onChange={(_, value) => {
                  if (value) {
                    setData({ ...data, path: value.path })
                  }
                }}
                defaultValue={{ path: data.path }}
                getOptionLabel={(obj: { path: string }) => obj.path}
                getOptionSelected={(option, value) =>
                  option.path === value.path
                }
                renderInput={(params: any) => (
                  <TextField {...params} label="Path" fullWidth />
                )}
              /> */}
            {/* </Grid> */}
          </Grid>
          {/* <Grid container>
            <Grid item xs={6} className={classes.spaceRow}>
              <TextField
                id="path"
                label="Path"
                defaultValue={data.path}
                fullWidth
                onChange={(ev) => {
                  setData({ ...data, path: ev.target.value })
                }}
              />
            </Grid>
          </Grid> */}
          <Grid container className={classes.spaceRow} justify="center">
            <Grid item xs={6}>
              <TextField
                id="meta-title"
                label="Title"
                defaultValue={data.title}
                fullWidth
                onChange={(ev) => {
                  setData({ ...data, title: ev.target.value })
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.spaceRow} justify="center">
            <Grid item xs={6}>
              <TextField
                id="meta-description"
                label="Description"
                defaultValue={data.description}
                fullWidth
                multiline
                rowsMax={6}
                onChange={(ev) => {
                  setData({ ...data, description: ev.target.value })
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </Fragment>
  )
}
