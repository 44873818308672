import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Divider,
  FormHelperText,
  Box,
} from '@material-ui/core'

import { Fragment, useContext, useState } from 'react'
import { UploadImage } from '@Components/Upload'
import {VideoContentContext, VideoContentContextType } from '../context/VideoContext'
import { styles } from '@Components/Upload/styles'
import useUpload from '@Hooks/useUpload'
import * as Icon from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles(styles)

export default function Images() {
  const classes = useStyles()
  const {
    handleChange,
    content: [content],
  } = useContext(VideoContentContext) as VideoContentContextType
  const [open, setOpen] = useState(false)
  const handleClickDelete = () => {
    setOpen((prev) => !prev)
  }

  const [uploadedMulti, setUploadedMulti] = useState<string[]>(
    content.images || [],
  )
  const [selected, setSelected] = useState<string[]>([])
  const onUploadedGalleryMulti = (images: string[]) => {
    const newArr = [...uploadedMulti, ...images]
    setUploadedMulti(newArr)
    setImageAll(newArr)
    handleChange('images', newArr)
  }

  const onConfirmDelete = () => {
    if (selected.length === 0) {
      setOpen(false)
      return
    }
    if (uploadedMulti.length > 0) {
      const newUploaded = uploadedMulti.filter((item: any, i: number) => {
        return selected.findIndex((item2) => item2 === item) === -1
      })

      handleChange('images', newUploaded)
      setUploadedMulti(newUploaded)
      setImageAll(newUploaded)
      setOpen(false)
      setSelected([])
    }
  }
  const handleSelected = (i: string) => {
    const is_exists = selected.findIndex((item) => item === i) !== -1
    if (is_exists) {
      setSelected((prev) => prev.filter((item) => item !== i))
    } else {
      setSelected((prev) => [...prev, i])
    }
  }

  /* Watermark */
  const [imageAll, setImageAll] = useState<string[]>(content.images || [])
  const { setWatermarks } = useUpload()
  const watermarkOptions = [
    { value: -1, title: '-เลือกรูปแบบลายน้ำ-' },
    { value: 0, title: 'ไม่ใส่ลายน้ำ' },
    { value: 1, title: 'แบบที่ 1 ดำมุมบนขวา' },
    { value: 2, title: 'แบบที่ 2 ขาวมุมบนขวา' },
    { value: 3, title: 'แบบที่ 3 ดำมุมล่างขวา' },
    { value: 4, title: 'แบบที่ 4 ขาวมุมล่างขวา' },
    { value: 5, title: 'แบบที่ 5 ดำล่างตรงกลาง' },
    { value: 6, title: 'แบบที่ 6 ขาวล่างตรงกลาง' },
  ]
  const [watermarkType, setwatermarkType] = useState<any>(-1)
  const [watermarkOpen, toggleWatermark] = useState(false)
  const handleClickWatermarkDialog = () => {
    setImageAll(uploadedMulti)
    toggleWatermark((prev) => {
      return !prev
    })
  }

  const handleCloseWatermarkDialog = () => {
    toggleWatermark(false)
    setWatermarkSelected([])
    setwatermarkType(-1)
    setReplaceURL([])
  }

  const [watermarkSelected, setWatermarkSelected] = useState<number[]>([])
  const handleWatermarkSelected = (key: number) => {
    const is_exists = watermarkSelected.findIndex((item) => item === key) !== -1
    if (is_exists) {
      setWatermarkSelected((prev) => prev.filter((item) => item !== key))
    } else {
      setWatermarkSelected((prev) => [...prev, key])
    }
  }
  // const { data } = await setWatermarks()

  const [replaceURL, setReplaceURL] = useState<{ old: string; new: string }[]>(
    [],
  )

  const handleWatermarkTypeChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    let type = event.target.value
    let urlsSelected = watermarkSelected.map((v) => imageAll[v])
    let rp: { new: string; old: string }[] = replaceURL

    //  setwatermarkType(type)
    const res = await setWatermarks(type, urlsSelected)

    const newImages = imageAll.map((item) => {
      let idx = urlsSelected.indexOf(item)
      if (idx !== -1) {
        let oldValue = item
        let newValue = res.data[idx]

        if (oldValue !== newValue) {
          rp.push({ old: oldValue, new: newValue })
        }

        return newValue
      }
      return item
    })

    setReplaceURL(rp)
    setImageAll(newImages)

    //  setWatermarkSelected([])
    setwatermarkType(-1)
  }

  const handleSaveWatermark = () => {
    setUploadedMulti(imageAll)

    // let newGallery = content.gallery
    let newThumbs = content.widgets[0].data[0].thumbnails
    let newContent = content.widgets[0].data[0].description
    let newShare = content.share
    let newCover = content.cover
    
    for (let url of replaceURL) {
      // newGallery = newGallery.join('|||').replace(url.old, url.new).split('|||')
      newThumbs = newThumbs.join('|||').replace(url.old, url.new).split('|||')
      newContent = newContent.replace(url.old, url.new)
      newCover = newCover.replace(url.old, url.new)
    }

    handleChange(
      [
        'images',
        'widgets[0].data[0].description',
        'widgets[0].data[0].thumbnails',
        'share',
        'cover',
      ],
      [imageAll, newContent, newThumbs, newShare, newCover],
    )
    handleCloseWatermarkDialog()
  }

  return (
    <Fragment>
      <Button
        onClick={handleClickDelete}
        className={classes.button_delete}
        size="small"
        startIcon={
          <Icon.Delete color={selected.length <= 0 ? 'disabled' : 'error'} />
        }
        color="secondary"
        disabled={selected.length <= 0}
      >
        ลบรูปภาพ
      </Button>

      <Button
        onClick={handleClickWatermarkDialog}
        className={classes.button_watermark}
        color="primary"
        size="small"
        startIcon={<Icon.Wallpaper />}
        disabled={uploadedMulti.length <= 0 || process.env.REACT_APP_THEME === 'mirror'}
      >
        Watermark
      </Button>

      {content?.id && (
        <UploadImage
          // title="Drag and Drop image(s) here"
          title={
            <div className={classes.title_custom}>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Drag and Drop image(s) here
              </Typography>
              <Typography
                variant="caption"
                align="center"
                component="p"
                color="textSecondary"
              >
                <div>
                  <b>Size Limit: </b>2 MB
                </div>
                <div>
                  <b>File: </b>jpg, png
                </div>
              </Typography>
            </div>
          }
          onUploaded={onUploadedGalleryMulti}
          multi
          uploadParams={{
            ref_id: content.id,
          }}
        />
      )}
      <Grid container className={classes.image_list} spacing={1}>
        <>
          {uploadedMulti &&
            uploadedMulti.map((item: string, index: number) => (
              <>
                <Grid key={`${item}_${index}`} item>
                  <div className={classes.image_wrapper}>
                    <img
                      draggable={false}
                      src={item}
                      className={clsx(
                        classes.image,
                        selected.findIndex((item2) => item2 === item) !== -1
                          ? classes.image_selected
                          : classes.image_before_selected,
                      )}
                      alt={item}
                      key={item}
                      onClick={() => handleSelected(item)}
                    />
                  </div>
                </Grid>
              </>
            ))}
        </>
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>ต้องการลบรูปภาพที่เลือกไว้ ?</DialogTitle>
        <DialogContent>
          <DialogContentText>จำนวน {selected.length} รูปภาพ</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onConfirmDelete}
            autoFocus
            startIcon={<Icon.Delete color="error" />}
            color="secondary"
            // variant="outlined"
          >
            ยืนยัน
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={watermarkOpen}
        onClose={handleCloseWatermarkDialog}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        fullWidth={true}
        // scroll="paper"
      >
        <DialogTitle id="scroll-dialog-title">Watermark</DialogTitle>
        <Divider />
        <DialogTitle id="scroll-dialog-title">
          <FormControl className={classes.formControl}>
            {/* <InputLabel>รูปแบบลายน้ำ</InputLabel> */}
            <Select
              autoFocus
              value={watermarkType}
              onChange={handleWatermarkTypeChange}
              inputProps={{
                name: 'watermark-type',
                id: 'watermark-type',
              }}
            >
              {watermarkOptions.map(
                (item: { value: number; title: string }, index: number) => (
                  <MenuItem value={item.value}>{item.title}</MenuItem>
                ),
              )}
            </Select>
            <FormHelperText>
              โปรดเลือกรูปแบบลายน้ำที่ต้องการใส่กับภาพที่เลือก
            </FormHelperText>
          </FormControl>

          <Box
            component="div"
            display="inline"
            p={1}
            bgcolor="background.paper"
          >
            <Button
              size="small"
              variant="outlined"
              className={classes.button}
              startIcon={<Icon.CheckBox />}
              onClick={() => {
                setWatermarkSelected(imageAll.map((v, i) => i))
              }}
            >
              เลือกทั้งหมด
            </Button>
          </Box>
          <Box
            component="div"
            display="inline"
            p={1}
            bgcolor="background.paper"
          >
            <Button
              size="small"
              variant="outlined"
              className={classes.button}
              startIcon={<Icon.CheckBoxOutlineBlank />}
              onClick={() => {
                setWatermarkSelected([])
              }}
            >
              ยกเลิกการเลือกทั้งหมด
            </Button>
          </Box>

          <Box
            component="div"
            display="block"
            textAlign="right"
            my={-2}
            className={classes.label_watermark}
          >
            จำนวน {watermarkSelected.length} จาก {imageAll.length} ภาพ
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container className={classes.image_list} spacing={1}>
              <>
                {imageAll &&
                  imageAll.map((item: string, index: number) => (
                    <>
                      <Grid key={`wtm_${item}_${index}`} item>
                        <div className={classes.image_wrapper}>
                          <img
                            draggable={false}
                            src={item}
                            className={clsx(
                              classes.image,
                              watermarkSelected.findIndex(
                                (item2) => item2 === index,
                              ) !== -1
                                ? classes.image_selected
                                : classes.image_before_selected,
                            )}
                            alt={item}
                            key={item}
                            onClick={() => handleWatermarkSelected(index)}
                          />
                        </div>
                      </Grid>
                    </>
                  ))}
              </>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSaveWatermark}
            className={classes.saveBtn}
          >
            บันทึก
          </Button>
          <Button variant="contained" onClick={handleCloseWatermarkDialog}>
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
