import { Fragment, useContext, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { QUERY_GET_CONTENT_BY_ID } from '@Api/content_api'
import Main from './main'
import Loading from '@Components/Loading'
import Widget from '../Widget/index'
import { Grid } from '@material-ui/core'

import { IContent } from '@Types/content_type'
import { ContentContext, ContentContextType } from '../context/ContentContext'
import isEmpty from 'lodash.isempty'

interface ParamType {
  id: string
}

export default function Detail() {
  const {
    content: [, setContent],
    onInitContent,
    publishNow: [, setPublishNow],
    onFetchSuggestionTags,
  } = useContext(ContentContext) as ContentContextType

  const { id } = useParams<ParamType>()
  const [fetch, { loading, error }] = useLazyQuery(QUERY_GET_CONTENT_BY_ID, {
    variables: {
      id: id,
    },
    onCompleted: async (data) => {
      if (data?.ContentDraft) {
        setContent(data.ContentDraft as IContent)
        if (data.ContentDraft.publish_time) {
          setPublishNow(false)
        }
        onFetchSuggestionTags(data.ContentDraft.topic[0]?.value)
      }
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    isEmpty(id) ? onInitContent() : fetch()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  if (loading) return <Loading />
  if (error) {
    return <p>Error :(</p>
  }

  return (
    <Fragment>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Main />
          <Widget />
        </Grid>
      </Grid>
    </Fragment>
  )
}
