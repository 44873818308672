import { useGlobal } from '@Hooks/useGlobal'
import React from 'react'
import SettingThairathPlus from './thairath_plus'

function SettingTopicCategory() {
  const { theme_name } = useGlobal()
  switch (theme_name) {
    case 'thairath-plus':
      return <SettingThairathPlus />
 
    default:
      return <>No theme</>
  }
}

export default SettingTopicCategory
