import { gql } from '@apollo/client'
export const MUTATION_PUBLISH_CONTENT = gql`
  mutation PublishContent(
    $id: String!
    $publish_time: Date
    $expire_time: Date
  ) {
    PublishVideo(
      id: $id
      publish_time: $publish_time
      expire_time: $expire_time
    ) {
      statusCode
      message
      data
    }
  }
`
export const QUERY_GET_ALL_CONTENTS = gql`
  query CmsVideosDraft($videoParams: VideoParams, $page: Int, $limit: Int) {
    CmsVideosDraft(params: $videoParams, page: $page, limit: $limit) {
      total
      data {
        id
        title
        widgets {
          layout
          data {
            title
            abstract
            description
            thumbnails
          }
        }
        JWPlayerId
        images
        status
        publish
        author {
          _id
          first_name
          last_name
        }
        premium
        topic {
          _id
          value
          label
        }
        categories {
          _id
          value
          label
        }
        tags
        publish_time(format: "DD/MM/YYYY HH:mm:ss")
        created_time
        expire_time
        summary
        created_by {
          first_name
          last_name
          nick_name
        }
        updated_time(format: "DD/MM/YYYY HH:mm:ss")
        thumbnail_1_1
        thumbnail_9_16
        thumbnail_share_9_16
        share
        cover
        content_type
        content_type_expire_time
      }
    }
  }
`

export const QUERY_GET_CONTENT_BY_ID = gql`
  query ($id: String) {
    CmsVideoDraft(id: $id) {
      id
      title
      widgets {
        layout
        data {
          title
          abstract
          description
          thumbnails
        }
      }
      JWPlayerId
      images
      status
      publish
      author {
        _id
        first_name
        last_name
      }
      premium
      topic {
        _id
        value
        label
      }
      categories {
        _id
        value
        label
      }
      tags
      token
      thumbnail_1_1
      thumbnail_9_16
      thumbnail_share_9_16
      share
      cover
      summary
      content_type
      content_type_expire_time
    }
  }
`

export const MUTATION_ADD_CONTENT = gql`
  mutation AddVideoDraft(
    $id: String
    $title: String
    $widgets: [WidgetInput]
    $JWPlayerId: String
    $images: [String]
    $status: String
    $publish: String
    $author: AuthorInput
    $premium: String
    $topic: [String]
    $categories: [String]
    $tags: [String]
    $publish_time: Date
    $expire_time: Date
    $summary: String
    $token: String
    $thumbnail_1_1: String
    $thumbnail_9_16: String
    $thumbnail_share_9_16: String
    $share: String
    $cover: String
    $content_type: String
    $content_type_expire_time: Date
  ) {
    AddVideoDraft(
      id: $id
      title: $title
      widgets: $widgets
      JWPlayerId: $JWPlayerId
      images: $images
      status: $status
      publish: $publish
      author: $author
      premium: $premium
      topic: $topic
      categories: $categories
      tags: $tags
      publish_time: $publish_time
      expire_time: $expire_time
      summary: $summary
      token: $token
      thumbnail_1_1: $thumbnail_1_1
      thumbnail_9_16: $thumbnail_9_16
      thumbnail_share_9_16: $thumbnail_share_9_16
      share: $share
      cover: $cover
      content_type: $content_type
      content_type_expire_time: $content_type_expire_time
    ) {
      statusCode
      message
      data
    }
  }
`

export const QUERY_GET_TOPIC_AND_CATEGORY = gql`
  query TopicsAndCategories($categoryParams: CategoryParams) {
    Topics {
      id: _id
      value: label
      topic_value: value
    }
    Categories(params: $categoryParams, order_by: { field: label, sort: asc }) {
      id: _id
      value: label
    }
  }
`

export const QUERY_GET_OPTION_SEARCH = gql`
  query {
    Topics {
      id: _id
      value: label
    }
    Categories {
      id: _id
      value: label
    }
    Authors: CmsAuthors {
      first_name
      last_name
      value: full_name
      id: _id
    }
    Follows: CmsFollows {
      id: _id
      value: title
    }
  }
`

export const QUERY_GET_CONTENT_HISTORY = gql`
  query getContentHistory($id: String) {
    CmsVideoHistory(id: $id) {
      created_by {
        first_name
        last_name
        profile_image
        description
      }
      publish_time(format: "DD/MM/YYYY HH:mm:ssZ")
    }
  }
`

export const MUTATION_EDIT_CONTENT = gql`
  mutation EditVideoDraft(
    $id: String
    $title: String
    $widgets: [WidgetInput]
    $JWPlayerId: String
    $images: [String]
    $status: String
    $publish: String
    $author: AuthorInput
    $premium: String
    $topic: [String]
    $categories: [String]
    $tags: [String]
    $publish_time: Date
    $expire_time: Date
    $summary: String
    $token: String
    $thumbnail_1_1: String
    $thumbnail_9_16: String
    $thumbnail_share_9_16: String
    $share: String
    $cover: String
    $content_type: String
    $content_type_expire_time: Date
  ) {
    EditVideoDraft(
      id: $id
      title: $title
      widgets: $widgets
      JWPlayerId: $JWPlayerId
      images: $images
      status: $status
      publish: $publish
      author: $author
      premium: $premium
      topic: $topic
      categories: $categories
      tags: $tags
      publish_time: $publish_time
      expire_time: $expire_time
      summary: $summary
      token: $token
      thumbnail_1_1: $thumbnail_1_1
      thumbnail_9_16: $thumbnail_9_16
      thumbnail_share_9_16: $thumbnail_share_9_16
      share: $share
      cover: $cover
      content_type: $content_type
      content_type_expire_time: $content_type_expire_time
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_CONTENT_STATUS = gql`
  mutation ($id: String!, $status: String!) {
    EditVideoStatus(id: $id, status: $status) {
      statusCode
      message
      data
    }
  }
`

export const QUERY_GET_RELATED_CONTENT = gql`
  query getRelated($id: String) {
    ContentDraft(id: $id) {
      related(is_cms: true) {
        id
        title
        abstract
        summary
        updated_time
        publish_time
        cover
        count_view
      }
    }
  }
`
