import { useEffect } from 'react'

import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import { QUERY_GET_ALL_AUTHORS } from '@Api/author_api'
import ButtonLink from '@Components/ButtonLink'
import LinkRouter from '@Components/LinkRouter'
import AddIcon from '@material-ui/icons/Add'
import { useQuery } from '@apollo/client'
import Loading from '@Components/Loading'
import { AuthContextType, useAuth } from '@Hooks/useAuth'

const columns: Array<MUIDataTableColumnDef> = [
  {
    name: '_id',
    label: '#',
    options: {
      filter: true,
      sort: true,
      customBodyRenderLite: (dataIndex) => <>{dataIndex + 1}</>,
    },
  },
  {
    name: 'first_name',
    label: 'First Name',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <LinkRouter to={`/author/edit/${rowData[0]}`}>{value}</LinkRouter>
      ),
    },
  },
  {
    name: 'last_name',
    label: 'Last Name',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, { rowData }) => (
        <LinkRouter to={`/author/edit/${rowData[0]}`}>{value}</LinkRouter>
      ),
    },
  },
  {
    name: 'nick_name',
    label: 'Nick Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'slug_name',
    label: 'Slug Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'is_active',
    label: 'สถานะ',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => (
        <>
          {value === '1' ? (
            <div style={{ color: 'green' }}>ใช้งาน</div>
          ) : (
            <div style={{ color: 'red' }}> ไม่ได้ใช้งาน</div>
          )}
        </>
      ),
    },
  },
  {
    name: 'last_update',
    label: 'Last Update',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
  // {
  //   name: '_id',
  //   label: 'แก้ไขข้อมูล',
  //   options: {
  //     filter: true,
  //     sort: true,
  //     customBodyRender: (value) => (
  //       <IconButtonLink to={`/author/edit/${value}`} size="small">
  //         <EditIcon color="primary" />
  //       </IconButtonLink>
  //     ),
  //   },
  // },
]
export default function Author() {
  const { checkPermission } = useAuth() as AuthContextType

  useEffect(() => {
    const checked = checkPermission('author_view')
    if (!checked) {
      window.location.href = '/'
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const { loading, error, data } = useQuery(QUERY_GET_ALL_AUTHORS)
  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <>
      <MUIDataTable
        title={'ผู้เขียนทั้งหมด'}
        columns={columns}
        data={data.Authors}
        options={{
          print: false,
          download: false,
          selectableRows: 'none',
          customToolbar: () => {
            return (
              <>
                <ButtonLink
                  to="/author/create"
                  startIcon={<AddIcon />}
                  style={{ order: -1 }}
                  variant="outlined"
                >
                  เพิ่มผู้เขียน
                </ButtonLink>
              </>
            )
          },
          setTableProps: () => {
            return {
              size: 'small',
            }
          },
        }}
      />
    </>
  )
}
