import { gql } from '@apollo/client'

export const QUERY_GET_TAG = gql`
  query get($section: String, $type: String) {
    CmsTag(type: $type, section: $section) {
      section
      value
      type
    }
  }
`
export const MUTATION_INSERT_TAG = gql`
  mutation insertTag($section: String, $value: [String], $type: String) {
    insertTag(section: $section, value: $value, type: $type) {
      statusCode
      message
      data
    }
  }
`
