import { useMutation } from '@apollo/client'
import { useState, createContext, useContext } from 'react'
import cookie from 'react-cookies'
import { MUTATION_LOGIN_USER } from '../api/user_api'
import { loginType, userType } from '@Types/user_type'
import jwt_decode from 'jwt-decode'
export const CMS_TR_PLUS_COOKIE = 'cms-token'

export const AuthContext = createContext({})

export const useAuth = () => {
  return useContext(AuthContext) as AuthContextType
}

export type AuthContextType = {
  user: userType
  login: ({ username, password }: loginType) => boolean
  logout: () => boolean
  errors: { message: string }
  checkPermission: (
    permission: string | string[],
    operation?: 'or' | 'and',
  ) => boolean
}
export const removeTokenUser = () =>
  cookie.remove(CMS_TR_PLUS_COOKIE, { path: '/' })
export const getTokenUser = () => cookie.load(CMS_TR_PLUS_COOKIE)
export const getDataPayloadJWT = () => {
  const token = getTokenUser()
  if (token) return jwt_decode(token) as userType
  else return undefined
}
export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user] = useState<userType | undefined>(getDataPayloadJWT())
  const [errors, setErrors] = useState<{ message: string }>()

  const [loginUser] = useMutation(MUTATION_LOGIN_USER)

  const login = async ({ username, password }: loginType): Promise<boolean> => {
    try {
      const {
        data: { LoginUser },
      } = await loginUser({
        variables: {
          username,
          password,
        },
      })
      if (LoginUser.statusCode !== 200) {
        setErrors({
          message: LoginUser.message,
        })
        return false
      }
      setErrors({ message: '' })
      const expires = new Date()
      expires.setDate(Date.now() + 1000 * 60 * 5)
      cookie.save(CMS_TR_PLUS_COOKIE, LoginUser.token, {
        path: '/',
        expires: expires,
        maxAge: 60 * 60 * 24,
      })
      return true
    } catch (error) {
      console.log('useAuth -> login', error)
      return false
    }
  }

  const logout = async (): Promise<boolean> => {
    try {
      removeTokenUser()
      return true
    } catch (error) {
      console.log('useAuth -> logout', error)
      return false
    }
  }

  const checkPermission = (
    permission: string | string[],
    operation?: 'or' | 'and',
  ) => {
    let checked: boolean = false
    let arrCheck: any = []
    const myPermission = user?.roles
    if (typeof permission === 'string') {
      checked = myPermission?.includes(permission) as boolean
    } else if (Array.isArray(permission)) {
      permission.forEach((per) => {
        arrCheck.push(myPermission?.includes(per) as boolean)
        switch (operation) {
          case 'or':
            checked = arrCheck.includes(true)
            break
          case 'and':
            let result = arrCheck.includes(false)
            checked = !result
            break
          default:
            checked = myPermission?.includes(per) as boolean
            break
        }
      })
    } else {
      checked = false
    }
    return checked
  }

  const store = {
    user,
    errors,
    login,
    logout,
    checkPermission,
  }

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
}
