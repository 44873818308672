import React, { Fragment, useEffect, useState } from 'react'
import { useSnackbar } from '@Hooks/useSnackbar'
import SaveIcon from '@material-ui/icons/Save'
import Loading from '@Components/Loading'
import {
  Grid,
  Paper,
  makeStyles,
  Card,
  Box,
  TextField,
  Typography,
  Button,
  Chip,
} from '@material-ui/core'
import { useAuth } from '@Hooks/useAuth'
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_GET_TAG, MUTATION_INSERT_TAG } from '@Api/tag_api'
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    width: '100%',
    // minHeight: 650,
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: 650,
    padding: theme.spacing(2),
  },
  container: {
    // backgroundColor: yellow[10 0],
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: '3px',
    boxShadow: '0 0 4px 2px rgba(230, 230, 230, 0.5)',
    // maxWidth: '350px',
    // minWidth: '250px',
    marginBottom: '20px',
  },
  title: {
    marginLeft: theme.spacing(1.5),
  },
  box: {
    padding: theme.spacing(2),
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },
  margin_left: {
    marginLeft: theme.spacing(1),
  },
  tag: {
    margin: theme.spacing(1),
  },
  button_save: {
    marginRight: theme.spacing(2),
  },
}))
const SeoTag = () => {
  const classes = useStyles()
  const { checkPermission } = useAuth()
  const [tagInput, setTagInput] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])
  const [insertTag] = useMutation(MUTATION_INSERT_TAG)
  const { onToggle } = useSnackbar()
  useEffect(() => {
    if (!checkPermission('seo_tag_pages_view')) {
      window.location.href = '/'
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { loading } = useQuery(QUERY_GET_TAG, {
    variables: {
      section: 'home',
      type: 'popular',
    },
    onCompleted: (data) => {
      setTags(data?.CmsTag?.value || [])
    },
  })

  const addTag = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      tag: { value: string }
    }
    const item = target.tag.value
    const checkDulpicat = tags.find((value) => value === item)
    if (!checkDulpicat) {
      if (item.trim() !== '') {
        setTags([...tags, item])
        setTagInput('')
      }
    }
  }

  const deleteTag = (item: String) => {
    const tagsNow = tags.filter((tag) => tag !== item)
    setTags(tagsNow)
  }

  const onSave = async () => {
    const res = await insertTag({
      variables: {
        section: 'home',
        value: tags,
        type: 'popular',
      },
    })
    if (res?.data?.insertTag?.statusCode === 200) {
      onToggle({
        type: 'success',
        open: true,
        message: 'บันทึกข้อมูลสำเร็จ!',
      })
    } else {
      onToggle({
        type: 'error',
        open: true,
        message: 'ไม่สามารถบันทึกข้อมูลได้!',
      })
      console.log(`LOG: cannot save editor pick ---> `, res.data.message)
    }
  }
  if (loading) return <Loading />
  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              Seo Tag
            </Typography>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center">
              <div className={classes.grow} />
              <Button
                className={classes.button_save}
                startIcon={<SaveIcon />}
                variant="outlined"
                type="submit"
                onClick={onSave}
              >
                บันทึกข้อมูล
              </Button>
            </Box>
          </Grid>
        </Grid>
        <form onSubmit={addTag} style={{ width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item>
              <TextField
                id="content-tag"
                name="tag"
                style={{ margin: 8, width: '300px' }}
                placeholder="input popular tag"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                value={tagInput}
                onChange={(ev) => {
                  setTagInput(ev.target.value)
                }}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="default"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
        <Card className={classes.container}>
          <Box className={classes.box}>
            <Typography
              color="textSecondary"
              className={classes.margin_left}
              gutterBottom
              variant="body2"
              component="span"
            >
              Tags
            </Typography>
            <Grid container spacing={2}>
              {tags.length > 0 ? (
                <Fragment>
                  {tags.map((item, index) => (
                    <Fragment key={`${item}_${index}`}>
                      <Chip
                        style={{ backgroundColor: '#63b1bb' }}
                        color="primary"
                        label={item}
                        onDelete={() => {
                          deleteTag(item)
                        }}
                        className={classes.tag}
                      />
                    </Fragment>
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  <Grid container justify="center">
                    <Grid item className={classes.box}>
                      No data
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Box>
        </Card>
      </Paper>
    </Fragment>
  )
}

export default SeoTag
