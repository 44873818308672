import { CustomTheme } from './../../theme/index'
import { Styles } from '@material-ui/styles'
const drawer_width = 240
export const styles: Styles<CustomTheme, {}, string> = (
  theme: CustomTheme,
) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  container: {
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  container_shift: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${drawer_width}px)`,
    marginLeft: drawer_width,
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    paddingInline: theme.spacing(1),
  },
  logo: {
    width: theme.custom.logo_size.width,
    height: theme.custom.logo_size.height,
    color: '#292929',
    alignSelf: 'center',
    marginTop: theme.spacing(2),
  },
  app_bar: {
    background: theme.custom.app_color,
    maxHeight: 64,
  },
  // app_bar_shift: {
  //   width: `calc(100% - ${drawer_width}px)`,
  // },
  drawer: {
    flexShrink: 0,
    width: drawer_width,
  },
  drawer_paper: {
    width: drawer_width,
    backgroundColor: '#292929',
    background: '#fff',
  },
  main: {
    background: '#f7f5f5',
    padding: theme.spacing(1),
    overflowY: 'scroll',
    flexGrow: 1,
    height: '100%',
  },
  list: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  nested: {
    color: '#fff',
    height: theme.spacing(5),
    paddingLeft: theme.spacing(4),
  },
  button: {
    color: '#fff',
    height: theme.spacing(5),
  },
  menu_icon: {
    marginRight: theme.spacing(1.5),
  },
  menu_paper: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  name_color: {
    color: '#fff',
    fontWeight: 'bold',
  },
  menu_active: {
    '&::before': {
      content: '""',
      width: 6,
      height: '85%',
      backgroundColor: theme.custom.app_color_light,
      position: 'absolute',
      right: 0,
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
    color: theme.custom.app_color_light,
    '& .MuiListItemIcon-root .MuiSvgIcon-root': {
      color: theme.custom.app_color_light,
    },
  },
})
