import { Fragment, useContext } from 'react'
import { useQuery } from '@apollo/client'
import {
  Typography,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Avatar,
} from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'
import { PodcastContext, PodcastContextType } from '../context/PodcastContext'
import { QUERY_GET_PODCAST_HISTORY } from '@Api/podcast_api'
import { IPodcast } from '@Types/podcast_type'
import moment from 'moment'

export default function History() {
  const {
    podcast: [podcast],
  } = useContext(PodcastContext) as PodcastContextType
  const { data } = useQuery(QUERY_GET_PODCAST_HISTORY, {
    variables: {
      id: podcast.id,
    },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <Fragment>
      <Typography variant="body1">ประวัติการเผยแพร่</Typography>

      {data?.CmsPodcastHistory &&
        data.CmsPodcastHistory.map((item: IPodcast) => (
          <List>
            <ListItem>
              {item?.created_by?.profile_image ? (
                <ListItemAvatar>
                  <Avatar
                    alt={item?.created_by.first_name}
                    src={item?.created_by?.profile_image}
                  />
                </ListItemAvatar>
              ) : (
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
              )}

              <ListItemText
                primary={`${item.created_by?.first_name} ${item.created_by?.last_name}`}
                secondary={moment(item.publish_time).locale('de').format("DD/MM/YYYY HH:mm:ss")}
              />
            </ListItem>
          </List>
        ))}
    </Fragment>
  )
}
