import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Grid,
  Link,
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { QUERY_GET_ALL_PODCAST } from '@Api/podcast_api'
import Loading from '@Components/Loading'
import { IPodcast } from '@Types/podcast_type'
import ButtonLink from '@Components/ButtonLink'
import SearchForm, { FormSearchType } from './Widget/searchForm'
import { Pagination } from '@material-ui/lab'
import { Add } from '@material-ui/icons'
import { PODCAST } from '@Libs/const'
import { useAuth } from '@Hooks/useAuth'
import { useGlobal } from '@Hooks/useGlobal'
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minHeight: 650,
  },
  pagination: {
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(1),
  },
  box_none: {
    boxShadow: 'none',
  },
  button_add: {
    marginInline: theme.spacing(1.5),
  },
}))

const ListPodcast = () => {
  const { checkPermission } = useAuth()
  const [addPodcastBtn, setAddPodcastBtn] = useState<boolean>(false)
  const { wording } = useGlobal()
  useEffect(() => {
    const checked = checkPermission('podcast_view')
    const editCheck = checkPermission('podcast_view')

    if (!checked) {
      window.location.href = '/'
    }
    if (editCheck) {
      setAddPodcastBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [pageInfo, setPageInfo] = useState({ page: 1, limit: 10 })
  const history = useHistory()
  const classes = useStyles()
  const [parameter, setParameter] = useState<FormSearchType>()
  const { loading, error, data, refetch } = useQuery(QUERY_GET_ALL_PODCAST, {
    variables: {
      podcastParams: {
        ...parameter,
        order_by: [
          {
            field: 'updated_time',
            sort: 'desc',
          },
        ],
      },
      ...pageInfo,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const handleSubmit = (form: FormSearchType) => {
    setPageInfo({ ...pageInfo, page: 1 })
    setParameter(form)
    refetch({
      podcastParams: {
        ...form,
        order_by: [
          {
            field: 'updated_time',
            sort: 'desc',
          },
        ],
        // has_video: false // for test has_video
      },
      limit: pageInfo.limit,
      page: 1,
    })
  }

  const handleChangePage = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({ ...pageInfo, page })
    refetch({
      podcastParams: {
        ...parameter,
        order_by: [
          {
            field: 'updated_time',
            sort: 'desc',
          },
        ],
        // has_video: false // for test has_video
      },
      limit: pageInfo.limit,
      page,
    })
  }

  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }

  return (
    <Fragment>
      <>
        <Paper className={classes.paper}>
          <SearchForm
            onSubmit={handleSubmit}
            size="small"
            actionButton={
              addPodcastBtn ? (
                <ButtonLink
                  className={classes.button_add}
                  to="/podcast/edit"
                  startIcon={<Add />}
                  style={{ order: -1 }}
                  variant="contained"
                  color="primary"
                >
                  เพิ่ม Podcast
                </ButtonLink>
              ) : (
                ''
              )
            }
          />
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: 100 }}>รหัส</TableCell>
                  <TableCell style={{ minWidth: 400 }} align="left">ชื่อเรื่อง</TableCell>
                  <TableCell align="left">{wording['channel']}</TableCell>
                  <TableCell style={{ width: 100 }} align="left">
                    สถานะ
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }} align="left">
                    แก้ไขล่าสุด
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }} align="left">
                    ผู้แก้ไขล่าสุด
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }} align="left">
                    เวลาเผยแพร่
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loading />
              ) : (
                <TableBody>
                  {data?.CmsPodcastsDraft &&
                    data?.CmsPodcastsDraft.data.map((item: IPodcast) => (
                      <TableRow hover>
                        <TableCell component="td" scope="row">
                          <Link
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push(`/podcast/edit/${item.id}`)
                            }}
                          >
                            {item.id}
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          <Link
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push(`/podcast/edit/${item.id}`)
                            }}
                          >
                            {item.title}
                          </Link>
                        </TableCell>
                        <TableCell align="left">{item?.channel?.map((item) => item.label).join(', ')}</TableCell>
                        <TableCell align="left">{PODCAST.STATUS_MAPPING[item.status]}</TableCell>
                        <TableCell align="left">{(item.updated_time && moment(item.updated_time).locale('th').format("DD/MM/YYYY HH:mm:ss")) || '-'}</TableCell>
                        <TableCell align="left">
                          {item.created_by &&
                            `${
                              item.created_by.first_name +
                              ' ' +
                              item.created_by.last_name
                            }`}
                        </TableCell>
                        <TableCell align="left">
                          {item.publish_time &&
                            moment(item.publish_time)
                              .locale('th')
                              .format('DD/MM/YYYY HH:mm:ss')}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Grid container>
            <div className={classes.grow} />
            <Grid item>
              <Pagination
                onChange={handleChangePage}
                page={pageInfo.page}
                className={classes.pagination}
                count={Math.ceil(data?.Podcasts?.total / pageInfo.limit)}
                showFirstButton
                showLastButton
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    </Fragment>
  )
}

export default ListPodcast
