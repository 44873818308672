///<reference path= "../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import { useContext, useEffect, useState } from 'react'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.css'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import './plugins/elle_quote.js'
import './plugins/elle_quote.css'
import './plugins/customEmbeded.js'
import './plugins/customEmbeded.css'
import './plugins/paragraphStyle.css'
import './plugins/qa_block.js'
import FroalaEditorComponent from 'react-froala-wysiwyg'
import { PodcastContext, PodcastContextType } from '../context/PodcastContext'
// import useUpload from '@Hooks/useUpload'
// import get from 'lodash.get'

// interface EditorProps {
//   index: number
// }

export default function SettingEditor() {
  const {
    handleChange,
    podcast: [podcast],
  } = useContext(PodcastContext) as PodcastContextType
  const [text, setText] = useState<string>(podcast?.abstract)
  const handleModelChange = (model: any) => {
    setText(model)
    if (text !== model) {
      handleChange('abstract', model)
    }
  }

  return (
    <>
     <FroalaEditorComponent
        tag="textarea"
        onModelChange={handleModelChange}
        model={text}
        config={{
          key: process.env.REACT_APP_FROALA_KEY,
          pastePlain: true,
          placeholderText: 'รายละเอียด',
          charCounterCount: false,
          attribution: false,
          // autofocus: true,
          linkAlwaysBlank: true,
          heightMin: 500,
          useClasses: true,
          imageDefaultWidth: 'auto',
          fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
          pluginsEnabled: [
            'align',
            'codeBeautifier',
            'codeView',
            'colors',
            // 'emoticons',
            // 'entities',
            // 'file',
            // 'fontAwesome',
            // 'fontFamily',
            'fontSize',
            'fullscreen',
            // 'image',
            // 'imageTUI',
            // 'imageManager',
            'inlineStyle',
            // 'inlineClass',
            // 'lineBreaker',
            // 'lineHeight',
            'link',
            'lists',
            'paragraphFormat',
            'paragraphStyle',
            // 'quickInsert',
            // 'quote',
            // 'save',
            // 'table',
            'url',
            // 'video',
            // 'wordPaste',
            // 'elle_quote',
            'customEmbeded',
            // 'qa_block',
            'insertHR'
          ],

          toolbarButtons: {
            moreText: {
              buttonsVisible: 4,
              buttons: [
                'bold',
                'italic',
                'underline',
                'fontSize',

                // more
                'textColor',
                'backgroundColor',
                'strikeThrough',
                'subscript',
                'superscript',
                // 'fontFamily',
                'inlineClass',
                'inlineStyle',
                'clearFormatting',
              ],
            },
            moreParagraph: {
              buttonsVisible: 5,
              buttons: [
                'paragraphStyle',
                'paragraphFormat',
                'formatUL',
                // 'elle_quote',
                // 'qa_block',

                // more
                // 'insertTable',
                'alignLeft',
                'alignCenter',
                'alignRight',
                'formatOL',
                'formatOLSimple',
                'alignJustify',
                'lineHeight',
                'outdent',
                'indent',
                // 'quote',
                'insertHR'
              ],
            },
            moreRich: {
              buttonsVisible: 4,
              buttons: [
                'insertLink',
                'imageManager',
                // 'imageUpload',
                'insertVideo',
                'customEmbededBtn',
                // 'insertImage',
                // 'insertTable',
                // 'emoticons',
                // 'fontAwesome',
                // 'specialCharacters',
                // 'insertFile',
                // 'insertHR',
              ],
            },
            moreMisc: {
              buttons: [
                'fullscreen',
                'clearFormatting',
                'html',

                // more
                'undo',
                'redo',
                // 'print',
                // 'getPDF',
                'spellChecker',
                // 'selectAll',
                'help',
              ],
              align: 'right',
              buttonsVisible: 3,
            },
          },
          paragraphStyles: {
            // qa_widget_title: 'QA Title',
            qa_widget_label: 'QA Label',
            qa_widget_question: 'QA Question',
            qa_widget_answer: 'QA Answer',
          },
          htmlRemoveTags: [''],
          // quickInsertButtons: ['image', 'ul', 'ol'],
          imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageManager'],
          videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
          // events: {
          //   initialized: function (this: any) {
          //     // console.log(`LOG: this ---> `, this)
          //   }
          // },
        }}
      />
    </>
  )
}
