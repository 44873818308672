import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  QUERY_GET_ALL_PODCAST,
  MUTATION_ADD_PODCAST,
  MUTATION_EDIT_PODCAST,
  MUTATION_PUBLISH_PODCAST,
  MUTATION_EDIT_PODCAST_STATUS,
} from '@Api/podcast_api'
import { IPodcast } from '@Types/podcast_type'
import set from 'lodash.set'
import debounce from 'lodash.debounce'
import { useHistory } from 'react-router'
import { PODCAST } from '@Libs/const'
import { IUserJWT } from '@Types/user_type'
import { useAuth } from '@Hooks/useAuth'
import isEmpty from 'lodash.isempty'
import { ISuggestionTag } from '@Types/suggestion_tag_type'
import { QUERY_GET_SUGGESTION_TAG_BY_SECTION } from '@Api/suggestion_tags_api'

export const PodcastContext = createContext({})

export interface IUserWriting {
  podcast_id: string
  user: IUserJWT
}
type PropertyType =
  | keyof IPodcast
  | Array<keyof IPodcast>
  | string
  | Array<string>

// แก้ any ด้วย
export type PodcastContextType = {
  podcast: [IPodcast, React.Dispatch<React.SetStateAction<IPodcast>>]
  publishNow: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  dialog: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  statusSave: StatusSaveType
  userWriting: IUserWriting[]
  isDisabled: boolean
  suggestionTags: ISuggestionTag | undefined
  handleChange: (property: PropertyType, value: any) => IPodcast
  handleSelectChange: (property: PropertyType, value: any) => void
  handleChangeStatus: (status: string) => void
  newPodcastDraft: () => IPodcast
  savePodcast: () => boolean
  savePodcastDraft: () => void
  onPublish: (publish_time?: string | Date, expire_time?: string | Date) => void
  onInitPodcast: () => void
  onFetchSuggestionTags: (section: string) => void
}

export type StatusSaveType = {
  loading: boolean
  status_code?: number
  error_message?: string
}

const getDefaultPodcast = () =>
({
  id: '',
  title: '',
  abstract: '',
  summary: '',
  widgets: [
    {
      layout: 'default',
      data: [
        {
          title: '',
          abstract: '',
          description: '',
          thumbnails: [],
        },
      ],
    },
  ],
  categories: [],
  topic: [],
  subtopic: [],
  tags: [],
  status: PODCAST.STATUS.DRAFT,
  assign_to: 'user_id',
  unlist: false,
  premium: PODCAST.PREMIUM_TYPE.MEMBERSHIP,
  allow_comment: false,
  cover: '',
  gallery: [],
  share: '',
  count_view: 0,
  token: '',
  images: [],
  thumbnail_share_9_16: '',
  thumbnail_1_1: '',
  thumbnail_9_16: '',
  podbean_url: '',
  podbean_id: '',
  duration: '',
  channel: []
} as IPodcast)

export const PodcastContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const history = useHistory()
  const [podcast, setPodcast] = useState<IPodcast>()
  const [publishNow, setPublishNow] = useState(true)
  const [addPodcast] = useMutation(MUTATION_ADD_PODCAST)
  const [editPodcast] = useMutation(MUTATION_EDIT_PODCAST)
  const [publishPodcast] = useMutation(MUTATION_PUBLISH_PODCAST)
  const [editPodcastStatus] = useMutation(MUTATION_EDIT_PODCAST_STATUS)
  const [statusSave, setStatusSave] = useState<StatusSaveType>({
    loading: false,
    status_code: 200,
  })
  const [dialog, setDialog] = useState(false)
  //const [gallery]                     = useState<string[]>()
  const [userWriting, setUserWriting] = useState<IUserWriting[]>([])
  const { user: userJwt, checkPermission } = useAuth()
  const [isDisabled, setIsDisabled] = useState(false)

  const onInitPodcast = () => {
    setPodcast(getDefaultPodcast())
  }

  // Tags
  const [suggestionTags, setSuggestionTags] = useState<ISuggestionTag>()
  const [fetchSuggestionTags] = useLazyQuery(
    QUERY_GET_SUGGESTION_TAG_BY_SECTION,
    {
      onCompleted: ({ CmsSuggestionTag }) => {
        setSuggestionTags(CmsSuggestionTag)
      },
    },
  )
  const onFetchSuggestionTags = useCallback(
    (section: string) => {
      fetchSuggestionTags({
        variables: { section },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [podcast?.topic],
  )

  const newPodcastDraft = async (data: IPodcast) => {
    const {
      data: { AddPodcastDraft },
      errors,
    } = await addPodcast({
      variables: data,
      refetchQueries: [
        {
          query: QUERY_GET_ALL_PODCAST,
          variables: {
            podcastParams: {
              order_by: [
                {
                  field: 'updated_time',
                  sort: 'desc',
                },
              ],
            },
          },
        },
      ],
    })
    if (isEmpty(errors)) {
      setStatusSave({
        loading: false,
        status_code: AddPodcastDraft?.statusCode,
      })
    }
    return AddPodcastDraft.data
  }

  const handleChange = (property: PropertyType, value: any): IPodcast => {
    if (!checkPermission('podcast_edit')) return {} as IPodcast
    console.log(`LOG: property ---> `, property, ` \nLOG:  value ---> `, value)
    setStatusSave({
      loading: true,
    })

    let newData = { ...podcast, status: PODCAST.STATUS.DRAFT } as IPodcast
    if (Array.isArray(property)) {
      for (let i = 0; i < property.length; i++) {
        const key = property[i]
        const val = value[i]
        newData = set<IPodcast>(newData, key, val)
      }
    } else {
      newData = set<IPodcast>(newData, property, value)
    }

    setPodcast(newData)
    saveDraftDelay(newData)
    return newData
  }

  const handleSelectChange = (property: PropertyType, value: any) => {
    if (!checkPermission('podcast_edit')) return
    console.log(`LOG: property ---> `, property, ` \nLOG:  value ---> `, value)
    setStatusSave({
      loading: true,
    })

    let newData = { ...podcast, status: PODCAST.STATUS.DRAFT } as IPodcast
    if (Array.isArray(property)) {
      for (let i = 0; i < property.length; i++) {
        const key = property[i]
        const val = value[i]
        newData = set<IPodcast>(newData, key, val)
      }
    } else {
      newData = set<IPodcast>(newData, property, value)
    }

    setPodcast(newData)
    saveDraft(newData)

  }

  const handleChangeStatus = async (status: string) => {
    if (!checkPermission('podcast_edit')) return
    if (!podcast?.id) return
    setStatusSave({
      loading: true,
    })
    const newData = set<IPodcast>({ ...podcast }, 'status', status)
    setPodcast(newData)
    const { data } = await editPodcastStatus({
      variables: {
        id: podcast.id,
        status: status,
      },
    })
    if (data?.EditPodcastStatus?.statusCode === 200) {
      setStatusSave({
        loading: false,
        status_code: 200,
      })
    } else {
      // alert('error')
      console.log(`LOG: error ---> `, data?.EditPodcastStatus?.message)
      setStatusSave({
        loading: true,
        status_code: 400,
      })
    }
  }

  const insertPodcastDraft = async (newData: IPodcast) => {
    if (!newData) {
      console.log('no data')
      return
    }
    newData = set(
      newData,
      'topic',
      newData.topic.map((item) => item?._id || item),
    )
    newData = set(
      newData,
      'categories',
      newData.categories.map((item) => item?._id || item),
    )
    newData = set(
      newData,
      'channel',
      newData.channel.map((item) => item?._id || item),
    )
    if (newData.id) {
      console.log('save', new Date().toLocaleTimeString())
      const { data } = await editPodcast({
        variables: newData,
      })
      if (data.EditPodcastDraft?.statusCode === 200) {
        setStatusSave({
          loading: false,
          status_code: 200,
        })
        setPodcast(data.EditPodcastDraft?.data)
        console.log('auto save success', newData.id)
      } else {
        setStatusSave({
          loading: false,
          status_code: 400,
          error_message: 'auto save fail',
        })
        console.log('auto save fail')
      }
    } else {
      const response = await newPodcastDraft(newData)
      if (!response) return
      newData.id = response.id
      setPodcast({ ...newData })
      history.push(`/podcast/edit/${newData.id}`)
      return
    }
  }

  const saveDraft = useCallback(debounce(insertPodcastDraft, 3000), []) // eslint-disable-line react-hooks/exhaustive-deps
  const saveDraftDelay = useCallback(debounce(insertPodcastDraft, 5000), []) // eslint-disable-line react-hooks/exhaustive-deps

  const onPublish = async (
    publish_time?: string | Date,
    expire_time?: string | Date,
  ) => {
    if (!checkPermission('content_publish_edit')) return
    const variables = {
      id: podcast?.id,
      publish_time: publishNow ? '' : publish_time,
      expire_time: !expire_time ? '' : expire_time,
    }
    const { data } = await publishPodcast({
      variables: variables,
    })
    console.log(`LOG: data ---> `, data)
    if (data.PublishPodcast?.statusCode === 200) {
      setPodcast(data.PublishPodcast.data)
    } else {
    }
  }

  // check user close tab or browser
  const onBeforeUnload = (e: BeforeUnloadEvent) => {
    if (statusSave.loading) {
      var confirmationMessage = 'o/'
        ; (e || window.event).returnValue = confirmationMessage //Gecko + IE
      return confirmationMessage //Webkit, Safari, Chrome
    }
  }

  const [isReconnect, setReconnect] = useState(0)

  useEffect(() => {
    if (podcast?.id) {
      let ws = new WebSocket(process.env.REACT_APP_WEB_SOCKET || '')

      ws.onopen = () => {
        const payload = {
          type: 'join',
          podcast_id: podcast?.id,
          user: userJwt,
        }
        ws.send(JSON.stringify(payload))
        if (isReconnect) {
          setReconnect(0)
        }
      }

      ws.onmessage = (e: any) => {
        const message = JSON.parse(e.data)
        // console.log(`LOG: message ---> `, message)
        const found = message?.users?.findIndex(
          (item: IUserWriting) => item.user._id === userJwt._id,
        )
        setIsDisabled(found !== 0)
        setUserWriting(message?.users)
      }

      ws.onerror = (e: any) => {
        ws.close()
      }

      ws.onclose = (e: any) => {
        // ไม่ได้เกิดจาก client close
        if (e.code === 1006) {
          setIsDisabled(true)
          setTimeout(() => {
            setReconnect(isReconnect + 1)
          }, 1000)
        } else {
          setReconnect(0)
        }
      }

      return () => {
        ws.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcast?.id, isReconnect])

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSave.loading])

  const store = {
    podcast: [podcast, setPodcast],
    publishNow: [publishNow, setPublishNow],
    dialog: [dialog, setDialog],
    statusSave,
    //gallery,
    userWriting,
    isDisabled,
    suggestionTags,
    handleChange,
    handleSelectChange,
    handleChangeStatus,
    newPodcastDraft,
    onPublish,
    onInitPodcast,
    onFetchSuggestionTags,
  }

  return (
    <PodcastContext.Provider value={store}>{children}</PodcastContext.Provider>
  )
}
